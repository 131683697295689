import React from "react";
import { Alert } from "react-bootstrap";

const AcknowledgmentPopup = ({popupVariant, popupMessage, popupIcon}) => {
    /*

     Set the parent component's - position: 'relative'.
    
    Example - 

    const handleFormSubmit = () => {
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 2000); // using this we hide popup after 2 sec automatically.
    };

    <AcknowledgmentPopup 
      popupVariant='success' 
      popupMessage='Payment Successful!' 
      popupIcon="fa-regular fa-circle-check" />

    */

    return <>
        <Alert
            variant={popupVariant}
            className="position-absolute top-50 start-50 translate-middle"
            style={{ zIndex: 1050, backgroundColor: 'white' }}
        >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 5 }}>
                <i className={popupIcon} /> {popupMessage}
            </div>
        </Alert>
        <div className="modal-backdrop fade show"></div>
    </>

}

export default AcknowledgmentPopup;