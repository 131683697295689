import React from 'react';
import { Modal, Row, Col, Button } from 'antd';

const RowDetailModal = ({ title, visible, onClose, columns, record }) => {
    return (
        <Modal
            title={
                <div style={{ display: "flex", justifyContent: "space-between", fontSize: '20px', borderBottom: '1px solid black', paddingBottom: '2px' }}>
                    <span>{title ? title : 'Details'}</span>
                    <Button danger onClick={onClose} style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        padding: 0,
                        boxShadow: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        paddingRight: '0px',
                        marginRight: '-3px',
                        marginTop: '-7px',
                    }}>
                        <i class="fa-solid fa-xmark" style={{ color: 'red', fontSize: '20px' }}></i>
                    </Button>
                </div>
            }
            open={visible}
            onCancel={onClose}
            footer={[
                <div key="footer" className='d-flex gap-2 justify-content-end'>{columns[columns.length - 1].render
                        ? columns[columns.length - 1].render(record[columns[columns.length - 1].dataIndex], record)
                        : record[columns[columns.length - 1].dataIndex]}

                </div>,
            ]}

        >
            <Row gutter={[16, 16]}>
                {columns.slice(0, -1).map((col, index) => {
                    if (col.dataIndex || col.render) {
                        return (
                            record[col.dataIndex] && <Col
                                key={col.key || index}
                                span={index === columns.length - 2 ? 24 : 8}
                            >
                                {record[col.dataIndex] && <>
                                    <strong>{col.title}:</strong>
                                    <div>
                                        {col.render
                                            ? col.render(record[col.dataIndex], record)
                                            : record[col.dataIndex]}
                                    </div></>}
                            </Col>
                        );
                    }
                    return null;
                })}
            </Row>
        </Modal>
    );
};

export default RowDetailModal;
