import React, { useEffect, useState } from "react";
import {
  Avatar_02,
  Avatar_05,
  Avatar_09,
  Avatar_10,
  Avatar_11,
  Avatar_12,
  Avatar_13,
} from "../../../Routes/ImagePath";
import { Link } from "react-router-dom";
import { Empty, Table } from "antd";
import EmployeeListFilter from "../../../components/EmployeeListFilter";
import Breadcrumbs from "../../../components/Breadcrumbs";
import AllEmployeeAddPopup from "../../../components/modelpopup/AllEmployeeAddPopup";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import SearchBox from "../../../components/SearchBox";
import config from "../../../config";
import User from "../../../assets/img/icons/user3.png";
import EmployeeSalaryDetail from "./EmployeeSalaryDetail";
import axios from "axios";

const EmployeesSalary = () => {

  const [showSalaryDetail, setShowSalaryDetail] = useState(false);
  const [salaryDetail, setSalaryDetail] = useState([]);
  const [selectedEmployeeDetail, setSelectedEmployeeDetail] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState([]);

  const user = localStorage.getItem("user");
  let department_id;
    if(user){
      const companyDetails = JSON.parse(user);
      department_id = companyDetails.department_id
    }

  const fetchEmployeesSalary = () =>{
    axios.get(`${config.API_BASE_URL}employees-salary/${department_id}`)
    .then((res)=>{
        const seen = new Set();
        const salaryData = res.data.data.filter(employee => seen.has(employee.employee_id) ? false : seen.add(employee.employee_id));
        console.log('res.data.data : ',res.data.data)
        setEmployeeDetails(res.data.data);
        setSalaryDetail(salaryData);
    })
    .catch((err)=>{
      console.log(err)
    })
    .finally(()=>{
      //loader
    })
  }

  useEffect(()=>{
   if(department_id){
   fetchEmployeesSalary();
   }
  },[])

  const getNameOfEmployee=()=>{
    if(selectedEmployeeDetail.length>0){
      const middle_name = selectedEmployeeDetail[0].middle_name? selectedEmployeeDetail[0].middle_name+' ' : '' 
    return selectedEmployeeDetail[0].first_name+' '+middle_name+selectedEmployeeDetail[0].last_name }
    return 'Employee'
  }

  function createEmployeeMap(employees) {
    const employeeMap = new Map();
    employees.forEach(employee => {
      if (!employeeMap.has(employee.employee_id)) {
        employeeMap.set(employee.employee_id, []);
      }
      employeeMap.get(employee.employee_id).push(employee);
    });
    return employeeMap;
  }
  
  function getEmployeeDataById(employeeMap, employee_id) {
    return employeeMap.get(employee_id) || [];
  }

  const filterSelectedEmployeeDetail = (selectedEmployee, employeeId) => {

    const employeeMap = createEmployeeMap(selectedEmployee);
    const employeeData = getEmployeeDataById(employeeMap, employeeId);

  return employeeData;
  }

  useEffect(()=>{
    getNameOfEmployee();
  }, [selectedEmployeeDetail])

  return (
    <div>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle={showSalaryDetail ? `${getNameOfEmployee()} Salary Details` : "Employees Salary"}
            title={showSalaryDetail ? 'Employees Salary' : "Dashboard"}
            subtitle={showSalaryDetail ? getNameOfEmployee()  : "Employees Salary"}
            titleLink={showSalaryDetail ? 'employees-salary' : null}
          />
        {!showSalaryDetail ? <><div className="row">
            {salaryDetail.length <= 0 ? <Empty description="No salary details available." /> :
            salaryDetail.map((detail) => (
              <div
                className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3"
                key={detail.id}
              >
                <div className="profile-widget">
                  <div className="profile-img">
                    <Link to='#' className="avatar" onClick={(e)=>{
                      e.preventDefault();
                      setShowSalaryDetail(true);
                      const employeeData = filterSelectedEmployeeDetail(employeeDetails, detail.employee_id)
                      setSelectedEmployeeDetail(employeeData);
                    }}>
                      <img src={detail.photo ? `${config.IMAGE_URL}${detail.photo}` : User} alt="" />
                    </Link>
                  </div>
                  <h4 className="user-name m-t-10 mb-0 text-ellipsis">
                    <Link to="#" onClick={(e)=>{
                      e.preventDefault();
                      setShowSalaryDetail(true);
                      const employeeData = filterSelectedEmployeeDetail(employeeDetails, detail.employee_id)
                      setSelectedEmployeeDetail(employeeData);
                    }}>{detail?.first_name} {detail?.middle_name} {detail?.last_name}</Link>
                  </h4>
                  <div className="small text-muted">{detail?.designation_name ? detail?.designation_name : '-'}</div>
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-end mb-4">
          {/* <Pagination
            current={currentPage}
            pageSize={paginationValue}
            total={data.length}
            // showSizeChanger
            onChange={handlePageChange}
            // pageSizeOptions={['5', '10', '15', '20']}
          /> */}
        </div> </> : <EmployeeSalaryDetail selectedEmployeeDetail={selectedEmployeeDetail}/>}
        {/* /Page Content */}
        {/* <AllEmployeeAddPopup /> */}
        <DeleteModal Name="Delete Employee" />
      </div>
      </div>
    </div>
  );
};

export default EmployeesSalary;
