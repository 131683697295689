export const convertAttendanceTotalHours=(hours)=> {
    if(!hours){
        return 0
    }
    const totalHours = Math.floor(hours);

    const minutes = Math.round((hours - totalHours) * 60);

    // const seconds = Math.round((((hours - totalHours) * 60) - minutes) * 60);

    // return `${totalHours} hr ${minutes} min ${seconds} sec`;
    return `${totalHours} hr ${minutes} min`;
}
