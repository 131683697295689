import React, { useState } from "react";
import {
  Avatar_02,
  Avatar_05,
  Avatar_09,
  Avatar_10,
  Avatar_11,
  Avatar_12,
  Avatar_13,
} from "../../../Routes/ImagePath";
import { Link } from "react-router-dom";
import { Table } from "antd";
import EmployeeListFilter from "../../../components/EmployeeListFilter";
import Breadcrumbs from "../../../components/Breadcrumbs";
import AllEmployeeAddPopup from "../../../components/modelpopup/AllEmployeeAddPopup";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import SearchBox from "../../../components/SearchBox";
import { convertDateFormate } from "../../../utils/convertDateFormate";
import { formatDecimal } from "../../../utils/formatDecimal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import config from "../../../config";
import { saveAs } from "file-saver";
import SalaryDetail from "../../../components/SalaryDetail";

const EmployeeSalaryDetail = ({selectedEmployeeDetail}) => {

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [salaryId, setSalaryId] = useState(null);
  const [salaryModalLoading, setSalaryModalLoading] = useState(true);
  const [salaryData, setSalaryData] = useState({});

  const employeeId = localStorage.getItem("employeeID");

  const handleCloseModal = () => {
    setShowModal(false);
    // setEmployId('');
  };

  const deleteSalary=()=>{
    setLoading(true);
    axios.put(`${config.API_BASE_URL}employee/salary-detail/${salaryId}`,{
      updated_by: employeeId
    })
    .then(res=>{
      console.log(res.data.message);
      setSalaryId(null);
    })
    .catch(err=>{
      console.log(err)
    })
    .finally(()=>{
      setLoading(false)
      // loader
    })
  }

  const columns = [
    {
      title: "Month",
      dataIndex: "month",
      render: (text) => {
        const monthYear = convertDateFormate(text)
        return new Date(monthYear.year,monthYear.month - 1).toLocaleString("default", { month: "long" })+' '+monthYear.year
      }
    },
    {
        title: "Total Salary",
        dataIndex: "total_salary",
        key: "total_salary",
        render:(text)=> formatDecimal(text)
      },
      {
        title: "Reimbursement",
        dataIndex: "reimbursement_allowance",
        key: "reimbursement_allowance",
        render:(text)=> formatDecimal(text)
      },
      {
        title: "Other deduction",
        dataIndex: "other_deduction",
        key: "other_deduction",
        render:(text)=> formatDecimal(text)
      },
      // {
      //   title: "Other Deduction Reason",
      //   dataIndex: "other_deduction_reason",
      //   key: "other_deduction_reason",
      //   render:(text)=> (text || '-')
      // },
      {
        title: "Total Deduction",
        dataIndex: "total_deduction",
        key: "total_deduction",
        render:(text)=> formatDecimal(text)
      },
      {
        title: "Net Salary",
        dataIndex: "net_salary",
        key: "net_salary",
        render:(text)=> formatDecimal(text)
      },
    {
      title: "Action",
      render: (text,record) => (
        <div className="action-menu text-end">
          <div className="d-flex gap-2 mt-2">
          <Link
              className="dropdown-item"
              onClick={(e)=>{
                e.preventDefault();
                     setLoading(true)
                     axios.get(`${config.API_BASE_URL}${record.salary_slip}`,{
                      responseType: "blob"
                    })
                     .then((res)=>{
                      const pdfBlob = new Blob([res.data], { type: "application/pdf" });
                      const pdfUrl = URL.createObjectURL(pdfBlob);
                      // if (option === "view") 
                        // window.open(pdfUrl, "_blank");
                      // else
                        saveAs(pdfBlob,record.salary_slip);
                     })
                     .catch((err)=>{
                      console.log(err)
                     })
                     .finally(()=>{
                      setLoading(false)
                     })
              }}
            >
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-view" style={{ fontSize: "12px", padding: "2px" }}>
                      View Salary Slip
                    </Tooltip>
                  }
                >
              <i className="fa fa-eye m-r-5 text-info" />
              </OverlayTrigger>
            </Link>
            <Link
              className="dropdown-item"
              onClick={(e)=>{
                e.preventDefault();
                setShowModal(true)
                setSalaryId(record.id);
                console.log(' Testing 4',record)
                setSalaryData({...record,
                  totalPay:record.total_salary,
                  otherAllowance: record.other_allowance,
                  medicalAllowance:record.medical_allowance,
                  statutoryBonus:record.statutory_bonus
                })
              }}
            >
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-view" style={{ fontSize: "12px", padding: "2px" }}>
                      Edit Salary
                    </Tooltip>
                  }
                >
              <i className="fa fa-pencil m-r-5 text-success" />
              </OverlayTrigger>
            </Link>
            <Link
              className="dropdown-item"
              data-bs-toggle="modal"
              data-bs-target="#delete_employee"
              onClick={(e)=>{
                e.preventDefault();
                setSalaryId(record.id);
              }}
            >
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-view" style={{ fontSize: "12px", padding: "2px" }}>
                    Delete Salary
                    </Tooltip>
                  }
                >
              <i className="fa fa-trash m-r-5 text-danger" />
              </OverlayTrigger>
            </Link>
          </div>
        </div>
      ),
    },
  ];
  return (
    <div>
          {/* <EmployeeListFilter /> */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <SearchBox />
                <Table
                  className="table-striped"
                  columns={columns}
                  dataSource={selectedEmployeeDetail}
                  rowKey={(record) => record.id}
                  loading={loading}
                />
              </div>
            </div>
          </div>
        {/* /Page Content */}
        {/* <AllEmployeeAddPopup /> */}
        <SalaryDetail
        show={showModal}
        handleClose={handleCloseModal}
        loading={salaryModalLoading}
        setLoading={setSalaryModalLoading}
        salaryId={salaryId}
        salaryData={salaryData}
        />
        <DeleteModal Name="Delete Salary" confirmDelete={deleteSalary}/>
     
    </div>
  );
};

export default EmployeeSalaryDetail;
