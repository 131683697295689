import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import config from "../config";
import { companyID } from "../auth";
import { calculateSalaryBreakdown } from "../utils/calculateSalaryBreakdown";
import { roundoffAmount } from "../utils/roundoffAmount";
import { Table } from "react-bootstrap";
import { convertDateFormate } from "../utils/convertDateFormate";
import { Spin } from "antd";
import { formatDecimal } from "../utils/formatDecimal";

const fields = [
    { key: "basic", label: "Basic" },
    { key: "provident_fund", label: "Provident fund" },
    { key: "hra", label: "HRA" },
    { key: "performance_bonus", label: "Performance bonus" },
    { key: "medicalAllowance", label: "Medical allowance" },
    { key: "other_deduction", label: "Other deduction" },
    { key: "statutoryBonus", label: "Statutory bonus" },
    { key: "", label: "" },
    { key: "otherAllowance", label: "Other allowance" },
    { key: "", label: "" },
    { key: "total_reimbursement", label: "Reimbursement allowance" },
    // "salary_eligible_days",
    // "total_attendance",
    // "total_holidays",
    // "total_leave",
    { key: "", label: "" },
    { key: "totalPay", label: "Gross salary" },
    { key: "total_deduction", label: "Total deduction" },
];

const SalaryDetail = ({ employeeId, show, handleClose, handleGenerate, loading, setLoading, salaryId, salaryData }) => {
    const [formData, setFormData] = useState({
        other_deduction: 0,
        provident_fund: 0,
        performance_bonus: 0,
        net_salary: 0,
        totalPay: 0,
        total_deduction: 0,
        other_deduction_reason: ''
    });

    const currentDate = new Date();

    const getMaxDate = () => {
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();
        let maxDate = `${currentYear}-${currentMonth < 10 ? "0" +currentMonth : currentMonth}`;
        return maxDate;
    }

    const [monthYear, setMonthYear] = useState(getMaxDate());

    console.log('monthYear : ', currentDate.getDate())

    const [companyWorkingDays, setCompanyWorkingDays] = useState(null);
    const [employeeWorkingDetail, setEmployeeWorkingDetail] = useState(null);
    console.log(' employeeWorkingDetail : ',employeeWorkingDetail)
    const [currentSalary, setCurrentSalary] = useState(null);
    const [companySalaryStructure, setCompanySalaryStructure] = useState(null);
    const [error, setError] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const totalDeduction = Number(formData.performance_bonus) + Number(formData.provident_fund) + Number(value)
        if (value > formData.net_salary) {
            setError(prevData => ({ ...prevData, [name]: 'The deduction amount exceeds the salary.',other_deduction_reason:'' }));
            setFormData({ ...formData, [name]: value, total_deduction: totalDeduction });
            return
        }
        setFormData({ ...formData, [name]: value, total_deduction: roundoffAmount(totalDeduction) });
        setError(prevData => ({ ...prevData, [name]: null, other_deduction_reason:'' }));
    };

    const handleDeductionReason = (e) => {
        const { name, value } = e.target;
        if (!value.trim()) {
            setError(prevData => ({ ...prevData, [name]: 'Please mention deduction reason.' }));
            setFormData({ ...formData, [name]: value });
        } else {
            setError(prevData => ({ ...prevData, [name]: null }));
            setFormData({ ...formData, [name]: value });
        }
    }

    const handleMonthYearChange = (e) => {
        
        const changeDate =(date)=>{
            setMonthYear(date);
        setFormData(prevData => ({
            ...prevData, other_deduction: 0, total_deduction: 0,
            other_deduction_reason: ''
        }))
        setError({});
        const inputField = document.getElementById("other_deduction");
        if (inputField) {
            inputField.value = "";
        }
        const deductionReasonInputField = document.getElementById("deduction_reason");
        if (deductionReasonInputField) {
            deductionReasonInputField.value = "";
        }
        }
        const maxDate = getMaxDate();
        const {year,month} = convertDateFormate(maxDate)
        console.log('year,month',`${year}-${month-1}`,':',(`${year}-${month-1}`) == e.target.value && currentDate.getDate() < 4)
        if(e.target.value < (`${year}-${month-1}`) ){
            changeDate(e.target.value);
            setError(prevData=>({...prevData,correctionPending:false}))
        }else if(((`${year}-${month-1}`) == e.target.value && currentDate.getDate() < 4) || maxDate == e.target.value ) {
            changeDate(e.target.value);
            setError(prevData=>({...prevData,correctionPending:true}))
        } else{
            changeDate(e.target.value);
            setError(prevData=>({...prevData,correctionPending:false}))
        }

    };

    const company_id = companyID();

    const selectedMonthYear = convertDateFormate(monthYear)

    const fetchCompanyWorkingDays = () => {
        setLoading(true);
        axios.get(`${config.API_BASE_URL}working-days/${company_id}/${selectedMonthYear.month}/${selectedMonthYear.year}`)
            .then((res) => {
                setCompanyWorkingDays(res.data.total_working_days);
                fetchEmployeementData(res.data.total_working_days);
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const fetchEmployeementData = (companyWorkingDays) => {
        axios.get(`${config.API_BASE_URL}employees/companies/${company_id}/employees/${employeeId}/salary-breakdown`, {
            params: { month: monthYear }
        })
            .then((res) => {
                setCurrentSalary(res.data.employmentData.salary);
                setCompanySalaryStructure(res.data.config);
                fetchEmployeeWorkingDetail(res.data.employmentData, res.data.config, companyWorkingDays);
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                // loader
            })
    }

    const fetchEmployeeWorkingDetail = (employmentData, companySalaryStructure, companyWorkingDays) => {
        axios.post(`${config.API_BASE_URL}calculate-salary`, {
            "employee_id": employeeId,
            "company_id": company_id,
            "month": selectedMonthYear.month,
            "year": selectedMonthYear.year
        })
            .then((res) => {
                setEmployeeWorkingDetail(res.data.salaryDetails[0])
                const per_day_salary = employmentData.salary / companyWorkingDays;
                const current_month_salary = per_day_salary * res.data.salaryDetails[0]?.salary_eligible_days || 0
                const roundedSalary = roundoffAmount(current_month_salary);
                const performance_bonus_amount = roundoffAmount((employmentData.performance_bonus * employmentData.salary) / 100)
                console.log(' performance_bonus_amount : ', performance_bonus_amount)
                const {
                    basic,
                    hra,
                    medicalAllowance,
                    statutoryBonus,
                    otherAllowance,
                    totalPay,
                } = calculateSalaryBreakdown(roundedSalary, companySalaryStructure)
                setFormData((prevData) => ({
                    ...prevData,
                    ...{
                        basic,
                        hra,
                        medicalAllowance,
                        statutoryBonus,
                        otherAllowance,
                        totalPay,
                    },
                    net_salary: roundoffAmount(Number(totalPay) - performance_bonus_amount - (employmentData?.provident_fund || prevData.provident_fund) - (employmentData?.other_deduction || prevData.other_deduction) + Number(roundoffAmount(employmentData.total_reimbursement))),
                    total_deduction: roundoffAmount(Number(performance_bonus_amount) + Number(employmentData.provident_fund || prevData.provident_fund) + Number(employmentData.other_deduction || prevData.other_deduction)),
                    ...res.data.salaryDetails[0],
                    performance_bonus: Number(performance_bonus_amount),
                    employee_salary: Number(employmentData.salary),
                    total_reimbursement: Number(roundoffAmount(employmentData.total_reimbursement)),
                    totalPay: Number(totalPay) + Number(roundoffAmount(employmentData.total_reimbursement))
                }))
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                // loader
            })
    }

    useEffect(() => {
        if (salaryId){
            console.log(' Testing 1')
           setLoading(false)
           setFormData(salaryData)
           setError(prevData=>({...prevData,correctionPending:false}));
           setMonthYear(salaryData.month)
        }
        else if (employeeId && monthYear) {
            console.log(' Testing 2')
            fetchCompanyWorkingDays();
        } else {
            console.log(' Testing 3')
            const maxDate =  getMaxDate();
            setMonthYear(maxDate);
            setError(prevData=>({...prevData,correctionPending:true}))
            // setError({})
            setFormData({
                other_deduction: 0,
                provident_fund: 0,
                performance_bonus: 0,
                net_salary: 0,
                totalPay: 0,
                total_deduction: 0,
                other_deduction_reason: ''
            })
            const inputField = document.getElementById("other_deduction");
            if (inputField) {
                inputField.value = "";
            }
            const deductionReasonInputField = document.getElementById("deduction_reason");
            if (deductionReasonInputField) {
                deductionReasonInputField.value = "";
            }
        }
    }, [show, employeeId, monthYear]);

    const companyThemeColor = localStorage.getItem('companyColor');

    const handleGenerateButton =()=>{
        const salaryData = { ...formData, 
            month: selectedMonthYear.month, 
            employee_id: employeeId, 
            company_id, 
            total_working_days: companyWorkingDays,
             ...employeeWorkingDetail, 
             currentMonthSalary: formData.totalPay, 
             slipMonth: new Date(selectedMonthYear.year, selectedMonthYear.month - 1).toLocaleString("default", { month: "short" }) + ' ' + selectedMonthYear.year, 
             salaryMonth: monthYear, 
             net_salary: roundoffAmount(formData?.net_salary - roundoffAmount(formData?.other_deduction)), 
             other_deduction: roundoffAmount(formData.other_deduction), 
             employee_salary: Number(formData.employee_salary), other_deduction_reason: Number(formData.other_deduction) ? formData.other_deduction_reason : null, reimbursement_allowance: formData.total_reimbursement || 0 
            }
        handleGenerate(salaryData);
    }

    const handleDisableGenerateButton=()=>{
       return error.other_deduction || (formData.other_deduction ? !formData.other_deduction_reason?.trim() : error.other_deduction_reason) ? true : false
    }

    return (
        <Modal show={show} centered>
            <Modal.Header className="d-flex justify-content-between ">
                <Modal.Title>Salary Details of {monthYear}</Modal.Title>
                {salaryId ? <></> : <Form.Group className="mb-0 d-flex justify-content-between">
                    <Form.Control
                        style={{ cursor: 'pointer' }}
                        id="monthInput"
                        type="month"
                        value={monthYear}
                        onChange={handleMonthYearChange}
                        max={getMaxDate()}
                    />
                </Form.Group>}
            </Modal.Header>
            {loading ? 
                <Spin style={{display:'flex',justifyContent:'center',alignItems:'center', margin:'10px'}} size="large" />: error?.correctionPending ? 
                <Modal.Body>
                    <p className="text-danger"> {`* You have to wait until the ${new Date(selectedMonthYear.year, selectedMonthYear.month).toLocaleString("default", { month: "long" })} 4th for salary of ${new Date(selectedMonthYear.year, selectedMonthYear.month-1).toLocaleString("default", { month: "long" })}.`}</p>
                </Modal.Body>
                : <Modal.Body>
                {salaryId ? <></>:<div>
                <div className="d-flex  gap-2 justify-content-between" style={{color:'black'}}><p>Company holidays: {employeeWorkingDetail?.total_holidays} Day</p>
                <p>Leaves: {formatDecimal(employeeWorkingDetail?.total_leave)} Day</p>
                <p>Attendance: {formatDecimal(employeeWorkingDetail?.total_attendance)} Day</p>
                </div>
                <div className="d-flex gap-2 justify-content-between" style={{color:'black'}}>
                <p>Employee worked: {formatDecimal(employeeWorkingDetail?.salary_eligible_days)} Day</p>
                <p>Company working: {companyWorkingDays} Day</p>
                </div>
                </div>}
                {(Number(employeeWorkingDetail?.total_attendance) || Number(employeeWorkingDetail?.total_leave) || salaryId) ? <Form>
                    <Row>
                        <Table bordered responsive>
                            <thead>
                                <tr style={{ textAlign: 'center' }}>
                                    <th colSpan={2} style={{ backgroundColor: companyThemeColor, color: 'white', textAlign: 'center' }}>Earnings</th>
                                    <th colSpan={2} style={{ backgroundColor: companyThemeColor, color: 'white', textAlign: 'center' }}>Deductions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fields.map((field, index) => {
                                    if (index % 2 === 0) {
                                        return (<>

                                            <tr key={field.key} >
                                                <td >{field.label}</td>
                                                <td ><strong className="text-capitalize">{formData[field.key] ? formatDecimal(formData[field.key]) : '-'}</strong></td>
                                                {fields[index + 1] ? (
                                                    <>
                                                        <td >{fields[index + 1].label}</td>
                                                        <td ><strong className="text-capitalize">{formData[fields[index + 1].key] ? formatDecimal(formData[fields[index + 1].key]) : '-'}</strong></td>
                                                    </>
                                                ) : (
                                                    <>
                                                        <td colSpan={2}></td>
                                                    </>
                                                )}
                                            </tr>
                                        </>);
                                    }
                                    return null;
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colSpan={2} style={{ backgroundColor: companyThemeColor, color: 'white', textAlign: 'center' }}>Net Salary</th>
                                    <th colSpan={2} style={{ backgroundColor: companyThemeColor, color: 'white', textAlign: 'end' }}>{formData?.net_salary ? roundoffAmount(formData?.net_salary - roundoffAmount(formData?.other_deduction)) : '-'}</th>
                                </tr>
                            </tfoot>
                        </Table>

                        {/* Input field for other_deduction */}
                        <Col sm={6} lg={6} md={6} className="mt-3">
                            <Form.Group>
                                <Form.Label> <strong className="text-capitalize">
                                    Other Deduction
                                </strong></Form.Label>
                                <Form.Control
                                    id="other_deduction"
                                    type="number"
                                    min={0}
                                    name="other_deduction"
                                    value={(formData.other_deduction)}
                                    onChange={handleInputChange}
                                    placeholder="Enter deduction amount"
                                />
                                {error.other_deduction && <span className="text-danger">* {error.other_deduction}</span>}
                            </Form.Group>
                        </Col>
                        {formData.other_deduction > 0 ? <Col sm={6} lg={6} md={6} className="mt-3">
                            <Form.Group>
                                <Form.Label> <strong className="text-capitalize">
                                    Deduction Reason {formData.other_deduction > 0 ? <span className="text-danger">*</span> : <></>}
                                </strong></Form.Label>
                                <Form.Control
                                    id="deduction_reason"
                                    as="textarea"
                                    min={0}
                                    name="other_deduction_reason"
                                    value={formData.other_deduction_reason}
                                    onChange={handleDeductionReason}
                                    placeholder="Enter deduction reason"
                                />
                                {error.other_deduction_reason && <span className="text-danger">* {error.other_deduction_reason}</span>}
                            </Form.Group>
                        </Col> : <></>}
                    </Row>
                </Form>: <p className="text-danger">* Not eligible for {new Date(selectedMonthYear.year, selectedMonthYear.month-1).toLocaleString("default", { month: "long" })} salary.</p>}
            </Modal.Body> }
            <Modal.Footer>
            <Button variant="danger" onClick={() => {
                    setFormData({
                        other_deduction: 0,
                        provident_fund: 0,
                        performance_bonus: 0,
                        net_salary: 0,
                        totalPay: 0,
                        total_deduction: 0,
                        other_deduction_reason: ''
                    })
                    setMonthYear('');
                    handleClose();
                }
                }>
                    Cancel
                </Button>
                {!error?.correctionPending && (Number(employeeWorkingDetail?.total_attendance) || Number(employeeWorkingDetail?.total_leave)) ?
                <Button variant="primary" disabled={handleDisableGenerateButton()} onClick={handleGenerateButton}>
                    Generate
                </Button>:<></>
                }
                {salaryId ? <Button variant="primary" disabled={handleDisableGenerateButton()} onClick={()=>{}}>
                    Update
                </Button>:<></>}
            </Modal.Footer>
        </Modal>
    );
};

export default SalaryDetail;
