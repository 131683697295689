import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Avatar_02, Avatar_09 } from "../../../Routes/ImagePath";
import { Table, Spin } from "antd";
import Breadcrumbs from "../../../components/Breadcrumbs";
import SearchBox from "../../../components/SearchBox";
import AddOverTime from "../../../components/modelpopup/AddOverTime";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import { getAllLeaves } from "../../../services/leaveService";
import { formatDateInTimezone } from "../../../utils/formatDateInTimezone";
import { getLabelByValue } from "../../../utils/getLabelByValue";
import { getAllExpenses } from "../../../services/reimbursementService";
import axios from "axios";
import config from "../../../config";
import { companyID } from "../../../auth";
import RowDetailModal from "../../../components/RowDetailModal";
import { useMediaQuery } from "react-responsive";
import { formatDecimal } from "../../../utils/formatDecimal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import { convertAttendanceTotalHours } from "../../../utils/convertAttendanceTotalHours";

const Taskbox = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [paginationValue, setPaginationValue] = useState(10);
  const [leaves, setLeaves] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [expenses, setExpenses] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(0);
  const [reimbursementId, setReimbursementId] = useState(null);
  const [acknowledge, setAcknowledge] = useState(false);
  const [acknowledgeAttachment, setAcknowledgeAttachment] = useState(null);
  const [updatedRemainingDaya, setUpdatedRemainingDays] = useState({});

  const employeeId = localStorage.getItem("employeeID");

  console.log("Task box leaves : ", leaves);
  console.log("Task box expenses : ", expenses);

  const fetchLeaves = async () => {
    const employee_id = localStorage.getItem("employeeID");
    try {
      const response = await axios.get(
        `${config.API_BASE_URL}api/reimbursement-for-approval/${employee_id}`
      );

      const response2 = await axios.get(
        `${config.API_BASE_URL}employeseApproval/${employee_id}`
      );
      const response3 = await axios.get(
        `${config.API_BASE_URL}leave-for-approval/${employee_id}`
      );
      const response4 = await axios.get(
        `${config.API_BASE_URL}api/reimbursementsForApproval/${employee_id}`
      );
      const response5 = await axios.get(
        `${config.API_BASE_URL}api/attendance/getAllAttendanceApprovals/${employee_id}`
      );
      let combinedData = [];

      if (response?.data?.result?.length > 0) {
        combinedData = [...combinedData, ...response?.data?.result];
      }
      if (response2?.data?.result?.length > 0) {
        combinedData = [...combinedData, ...response2?.data?.result];
      }
      if (response3?.data?.result?.length > 0) {
        combinedData = [...combinedData, ...response3?.data?.result];
      }
      if (response4?.data?.result?.length > 0) {
        combinedData = [...combinedData, ...response4?.data?.result];
      }
      if (response5?.data?.data?.length > 0) {
        combinedData = [...combinedData, ...response5?.data?.data];
      }

      setLeaves(combinedData);
    } catch (err) {
      console.log(err.message);
    } finally {
      setLoading(false)
    }

  };
  useEffect(() => {
    fetchLeaves();
  }, [loading]);

  const company_id = companyID();
  console.log("taskbocapproval", leaves);

  const leavetype = [
    { value: "", label: "Select leave type" },
    { value: 1, label: "Sick Leave" },
    { value: 2, label: "Casual Leave" },
    { value: 3, label: "Vacation" },
    { value: 4, label: "Unpaid Leave" },
    { value: 5, label: "Compensatory Off Leave" },
    
  ];

  const leavePeriod = [
    { value: 1, label: "Full day" },
    { value: 2, label: "First half" },
    { value: 3, label: "Second half" },
  ];

  const reasonType = [
    { value: 1, label: 'Not in location issue' },
    { value: 2, label: 'App not loading properly' },
    { value: 3, label: 'Forgot to login in app' },
    { value: 4, label: 'Forgot to logout from app' },
    { value: 5, label: 'New Joinee Registration Process Not Completed'},
    { value: 6, label: 'Weather-related delay'},
    { value: 7, label: 'Public transport delays.'},
    { value: 8, label: 'Traffic congestion or road closure.'},
    { value: 9, label: 'Unexpected personal emergencies'},
    { value: 10, label: 'Network outage.'},
    { value: 11, label: 'Other.'}
  ];

  const leaveReason = [
    { value: 1, label: "Illness" },
    { value: 2, label: "Family Emergency" },
    { value: 3, label: "Childcare" },
    { value: 4, label: "Vacation Travel" },
    { value: 5, label: "Personal Reasons" },
    { value: 6, label: "Medical Appointment" },
  ];

  const leaveElements = leaves
    .map((data, index) => ({
      key: index + 1,
      name: data?.first_name + " " + data?.last_name,
      type: getLabelByValue(leavetype, data.leave_type) || data.expense_type || 'Attendance',
      leave_type_id: data?.leave_type,
      leave_days: data?.leave_days,
      appliedOn: formatDateInTimezone(data.created_at),
      appliedfor: data.start_date
        ? `${formatDateInTimezone(data.start_date)} to ${formatDateInTimezone(
          data.end_date
        )}`
        : `${formatDateInTimezone(data.expense_date || data.attendance_for_approval)}`,
      status: data?.updated_status || data?.expense_status,
      task_type: data.expense_type ? "REIMBURSEMENT" : getLabelByValue(leavetype, data.leave_type) ? "LEAVE" : 'ATTENDANCE',
      employee_id: data.employee_id,
      task_id: data.id || data.attendance_id,
      updated_status: data?.updated_status || 1,
      attachment: data?.attachment,
      applicant_id: data?.applicant_id,
      approval_id: data?.approval_id,
      message: data.message,
      reason: getLabelByValue(leaveReason, data.leave_reason) || data.expense_type || getLabelByValue(reasonType, data.reason),
      leave_days: formatDecimal(data.leave_days),
      leave_apply_period: getLabelByValue(leavePeriod, data.leave_apply_period),
      punchIn:data.punchIn ?  moment(data.punchIn).format("hh:mm:ss A"): null,
      punchOut:data.punchOut ? moment(data.punchOut).format("hh:mm:ss A"): null,
      totalHours:data.totalHours ? convertAttendanceTotalHours(data.totalHours): null,
    }))
    .sort((a, b) => new Date(b.appliedOn) - new Date(a.appliedOn));

  const actionStatus = [
    { value: 2, label: "Approve" },
    { value: 3, label: "Reject" },
  ];
  //------------------------ approval action handling------------------------

  const actionStatusHandler = async (task_id, task_type, status_id) => {
    const employeeId = localStorage.getItem("employeeID");
    closeModal();
    // console.log( "task_is" ,response );
    if (task_type == "LEAVE") {
      try {
        setLoading(true);
        const response = await axios.post(
          `${config.API_BASE_URL}leave/${task_id}/approve/`,
          {
            company_id: company_id,
            approver_id: employeeId,
            status_id: status_id,
            leave_employee_id: updatedRemainingDaya?.leave_employee_id,
            no_of_days: updatedRemainingDaya?.no_of_days,
            leave_type_id: updatedRemainingDaya?.leave_type_id
          }
        );
        if (response) {
          fetchLeaves();
        }
      } catch (error) {
        console.error("Error updating leave:", error);
      }
      finally {
        setLoading(false)
      }
    } else {
      try {
        setLoading(true);
        const response = await axios.post(
          `${config.API_BASE_URL}api/reimbursementsForApprova/${task_id}`,
          {
            approver_id: employeeId,
            status_id: status_id,
            company_id: company_id
          }
        );
        if (response) {
          setCurrentStatus(status_id);
          fetchLeaves();
        }
      } catch (error) {
        console.error("Error approving reimbursement:", error);
      }
      finally {
        setLoading(false);
        setIsModalOpen(false);
      }
    }
  };

  const updateApprovalStatus = async (approval_id, status, applicant_id, date, attendance_id) => {
    axios
      .put(
        `${config.API_BASE_URL}api/attendance/updateAttendanceApproval/${approval_id}/${applicant_id}`,
        {
          "status": status,
          "updated_by": employeeId,
          "date": date,
          "attendance_id": attendance_id
        }
      )
      .then(res => {
        if (res.data.success == "true") {
          // popup
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const attendaceApproval = async (applicant_id, date, approval_id, status, attendance_id) => {
    setLoading(true);
    closeModal();
    if (status == "Approve") {
      axios
        .put(
          `${config.API_BASE_URL}api/attendance/attendace/${applicant_id}/${date}/${company_id}/${employeeId}`
        )
        .then(res => {
          console.log("response after approval of attendance : ", res)
          updateApprovalStatus(approval_id, "approved", applicant_id, date, attendance_id)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          setLoading(false)
        })
    }
    else {
      await updateApprovalStatus(approval_id, "rejected", applicant_id, date, attendance_id);
      setLoading(false)
    }
  }

  const columns = [
    // {
    //   title: "#",
    //   dataIndex: "key",
    //   sorter: (a, b) => a.id.length - b.id.length,
    // },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => <span className="table-avatar" style={{ cursor: 'pointer' }} onClick={() => onRowClick(record)}>{text}</span>,

    },
    {
      title: "Type",
      dataIndex: "type",
      render: (text, record) => <span className="table-avatar" style={{ cursor: 'pointer' }} onClick={() => onRowClick(record)}>{text}</span>
    },

    {
      title: "Applied on",
      dataIndex: "appliedOn",
      render: (text, record) => <span className="table-avatar" style={{ cursor: 'pointer' }} onClick={() => onRowClick(record)}>{text}</span>
    },

    {
      title: "Applied for",
      dataIndex: "appliedfor",
      render: (text, record) => <span className="table-avatar" style={{ cursor: 'pointer' }} onClick={() => onRowClick(record)}>{text}</span>
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <span
          onClick={() => onRowClick(record)}
          style={{
            color:
              record.updated_status === 1 || record.updated_status === "pending"
                ? "rgb(255 223 0)"
                : record.updated_status === 2 || record.updated_status === 'approved'
                  ? "green"
                  : "red",
            cursor: 'pointer'
          }}
        >
          {record.updated_status === 1 || record.updated_status == "pending"
            ? "Pending"
            : record.updated_status === 2 || record.updated_status == 'approved'
              ? "Approved"
              : "Rejected"}
        </span>
      ),

    },

    {
      title: "Action",
      render: (record) => (
        <div className="action-menu">
            {record.task_type === "REIMBURSEMENT" ? (
              <div className='d-flex gap-2 mt-2' style={{cursor:record.updated_status === 1?'pointer':'not-allowed'}}>
              {actionStatus.map((action, index) => (
                
              <button
                disabled={record.updated_status === 1? false:true}
                className="list-group-item list-group-item-action"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalFullscreen"
                onClick={(e) => {
                  e.preventDefault();
                  setIsModalOpen(true)
                  setCurrentStatus(record?.updated_status);
                  setReimbursementId(record?.task_id);
                  setAcknowledgeAttachment(record?.attachment);
                  console.log("record for id of leave : ", record.key);
                }}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-view" style={{ fontSize: "12px", padding: "2px" }}>
                       {action.label == "Approve" ? action.label : action.label}
                    </Tooltip>
                  }
                >
                  <i
                      className={`fa-regular fa-circle-${action.label == "Approve" ? 'check' : 'xmark'} ${record.updated_status != 1?'text-muted' :action.label == "Approve"
                          ? "text-success"
                          : "text-danger"
                        } `}
                    />
                </OverlayTrigger>
              </button>
              ))}
              </div>
            ) : record.task_type === "LEAVE" ? (
              <div className='d-flex gap-2 mt-2' style={{cursor:record.updated_status === 1?'pointer':'not-allowed'}}>
              {actionStatus.map((action, index) => (
                
                <button
                disabled={record.updated_status === 1? false:true}
                  className="list-group-item list-group-item-action"
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("record for id of leave : ", record.key);
                    actionStatusHandler(
                      record.task_id,
                      record.task_type,
                      action.value
                    );
                    setUpdatedRemainingDays({
                      leave_employee_id: record?.employee_id,
                      no_of_days: record?.leave_days,
                      leave_type_id: record?.leave_type_id
                    });
                  }}
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-view" style={{ fontSize: "12px", padding: "2px" }}>
                        {action.label == "Approve" ? action.label : action.label}
                      </Tooltip>
                    }>
                    <i
                      className={`fa-regular fa-circle-${action.label == "Approve" ? 'check' : 'xmark'} ${record.updated_status != 1?'text-muted' :action.label == "Approve"
                          ? "text-success"
                          : "text-danger"
                        } `}
                        
                    />
                  </OverlayTrigger>

                </button>
                
              ))}
              </div>
            ) : (
              <div className='d-flex gap-2 mt-2' style={{cursor:record.updated_status === 'pending'?'pointer':'not-allowed'}}>
              {actionStatus.map((action, index) => (
                
                <button
                 disabled={record.updated_status === "pending"? false:true}
                  className="list-group-item list-group-item-action"
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("record for id of leave : ", record);
                    attendaceApproval(record.applicant_id, record.appliedfor, record.approval_id, action.label, record.task_id)
                  }}
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-view" style={{ fontSize: "12px", padding: "2px" }}>
                        {action.label == "Approve" ? action.label : action.label}
                      </Tooltip>
                    }>
                    <i
                      className={`fa-regular fa-circle-${action.label == "Approve" ? 'check' : 'xmark'} ${record.updated_status != "pending"?'text-muted' :action.label == "Approve"
                          ? "text-success"
                          : "text-danger"
                        } `}
                    />
                  </OverlayTrigger>
                </button>
                
              ))}
              </div>
            )}
        </div>
      ),
    },
  ];

  const smallScreencolumns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => <span className="table-avatar" style={{ cursor: 'pointer' }} onClick={() => onRowClick(record)}>{text}</span>,

    },
    {
      title: "Type",
      dataIndex: "type",
      render: (text, record) => <span className="table-avatar" style={{ cursor: 'pointer' }} onClick={() => onRowClick(record)}>{text}</span>
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <span
          onClick={() => onRowClick(record)}
          style={{
            color:
              record.updated_status === 1 || record.updated_status === "pending"
                ? "rgb(255 223 0)"
                : record.updated_status === 2 || record.updated_status === 'approved'
                  ? "green"
                  : "red",
            cursor: 'pointer'
          }}
        >
          {record.updated_status === 1 || record.updated_status == "pending"
            ? "Pending"
            : record.updated_status === 2 || record.updated_status == 'approved'
              ? "Approved"
              : "Rejected"}
        </span>
      ),

    },
    {
      title: "Action",
      render: (record) => (
        <div className="action-menu">
            {record.task_type === "REIMBURSEMENT" ? (
              <div className='d-flex gap-2 mt-2' style={{cursor:record.updated_status === 1?'pointer':'not-allowed'}}>
              {actionStatus.map((action, index) => (
                
              <button
                disabled={record.updated_status === 1? false:true}
                className="list-group-item list-group-item-action"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalFullscreen"
                onClick={(e) => {
                  e.preventDefault();
                  setIsModalOpen(true)
                  setCurrentStatus(record?.updated_status);
                  setReimbursementId(record?.task_id);
                  setAcknowledgeAttachment(record?.attachment);
                  console.log("record for id of leave : ", record.key);
                }}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-view" style={{ fontSize: "12px", padding: "2px" }}>
                       {action.label == "Approve" ? action.label : action.label}
                    </Tooltip>
                  }
                >
                  <i
                      className={`fa-regular fa-circle-${action.label == "Approve" ? 'check' : 'xmark'} ${record.updated_status != 1?'text-muted' :action.label == "Approve"
                          ? "text-success"
                          : "text-danger"
                        } `}
                    />
                </OverlayTrigger>
              </button>
              ))}
              </div>
            ) : record.task_type === "LEAVE" ? (
              <div className='d-flex gap-2 mt-2' style={{cursor:record.updated_status === 1?'pointer':'not-allowed'}}>
              {actionStatus.map((action, index) => (
                
                <button
                disabled={record.updated_status === 1? false:true}
                  className="list-group-item list-group-item-action"
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("record for id of leave : ", record.key);
                    actionStatusHandler(
                      record.task_id,
                      record.task_type,
                      action.value
                    );
                    setUpdatedRemainingDays({
                      leave_employee_id: record?.employee_id,
                      no_of_days: record?.leave_days,
                      leave_type_id: record?.leave_type_id
                    });
                  }}
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-view" style={{ fontSize: "12px", padding: "2px" }}>
                        {action.label == "Approve" ? action.label : action.label}
                      </Tooltip>
                    }>
                    <i
                      className={`fa-regular fa-circle-${action.label == "Approve" ? 'check' : 'xmark'} ${record.updated_status != 1?'text-muted' :action.label == "Approve"
                          ? "text-success"
                          : "text-danger"
                        } `}
                        
                    />
                  </OverlayTrigger>

                </button>
                
              ))}
              </div>
            ) : (
              <div className='d-flex gap-2 mt-2' style={{cursor:record.updated_status === 'pending'?'pointer':'not-allowed'}}>
              {actionStatus.map((action, index) => (
                
                <button
                 disabled={record.updated_status === "pending"? false:true}
                  className="list-group-item list-group-item-action"
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("record for id of leave : ", record);
                    attendaceApproval(record.applicant_id, record.appliedfor, record.approval_id, action.label, record.task_id)
                  }}
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-view" style={{ fontSize: "12px", padding: "2px" }}>
                        {action.label == "Approve" ? action.label : action.label}
                      </Tooltip>
                    }>
                    <i
                      className={`fa-regular fa-circle-${action.label == "Approve" ? 'check' : 'xmark'} ${record.updated_status != "pending"?'text-muted' :action.label == "Approve"
                          ? "text-success"
                          : "text-danger"
                        } `}
                    />
                  </OverlayTrigger>
                </button>
                
              ))}
              </div>
            )}
        </div>
      ),
    },
  ];

  const inDetailcolumns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => <span className="table-avatar" onClick={() => onRowClick(record)}>{text}</span>,

    },
    {
      title: "Type",
      dataIndex: "type",
      render: (text, record) => <span className="table-avatar" onClick={() => onRowClick(record)}>{text}</span>
    },

    {
      title: "Applied on",
      dataIndex: "appliedOn",
    },

    {
      title: "Applied for",
      dataIndex: "appliedfor",
    },
    {
      title: "Punch In",
      dataIndex: "punchIn",
    },
    {
      title: "Punch Out",
      dataIndex: "punchOut",
    },
    {
      title: "Total Hours",
      dataIndex: "totalHours",
    },
    {
      title: "Leave days",
      dataIndex: "leave_days",
    },
    {
      title: "Leave apply period",
      dataIndex: "leave_apply_period",
    },
    {
      title: "Reason",
      dataIndex: "reason",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <span
          onClick={() => onRowClick(record)}
          style={{
            color:
              record.updated_status === 1 || record.updated_status === "pending"
                ? "rgb(255 223 0)"
                : record.updated_status === 2 || record.updated_status === 'approved'
                  ? "green"
                  : "red",
          }}
        >
          {record.updated_status === 1 || record.updated_status == "pending"
            ? "Pending"
            : record.updated_status === 2 || record.updated_status == 'approved'
              ? "Approved"
              : "Rejected"}
        </span>
      ),

    },
    {
      title: "Message",
      dataIndex: "message",
    },
    {
      title: "Action",
      render: (text, record) => (
        <div className="action-menu">
            {record.task_type === "REIMBURSEMENT" ? (
              <div className='d-flex gap-2 mt-2' style={{cursor:record.updated_status === 1?'pointer':'not-allowed'}}>
                {actionStatus.map((action, index) => (
              <button
                type="button"
                disabled={record.updated_status === 1? false:true}
                className={`btn btn-outline-${record.updated_status != 1?'text-muted' :action.label == "Approve"
                  ? "success"
                  : "danger"
                }`}
                data-bs-toggle="modal"
                data-bs-target="#exampleModalFullscreen"
                onClick={(e) => {
                  e.preventDefault();
                  setIsModalOpen(true)
                  setCurrentStatus(record?.updated_status);
                  setReimbursementId(record?.task_id);
                  setAcknowledgeAttachment(record?.attachment);
                  console.log("record for id of leave : ", record.key);
                }}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-view" style={{ fontSize: "12px", padding: "2px" }}>
                      View attachment
                    </Tooltip>
                  }
                >
                  <> {action.label == "Approve" ? 'Approve' : 'Reject'}
                  </>
                </OverlayTrigger>
              </button>))}</div>
            ) : record.task_type === "LEAVE" ? (
              <div className='d-flex gap-2 mt-2' style={{cursor:record.updated_status === 1?'pointer':'not-allowed'}}>
              {actionStatus.map((action, index) => (
                <button
                disabled={record.updated_status === 1? false:true}
                  type="button"
                  className={`btn btn-outline-${record.updated_status != 1?'text-muted' :action.label == "Approve"
                    ? "success"
                    : "danger"
                  }`}
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("record for id of leave : ", record.key);
                    actionStatusHandler(
                      record.task_id,
                      record.task_type,
                      action.value
                    );
                    setUpdatedRemainingDays({
                      leave_employee_id: record?.employee_id,
                      no_of_days: record?.leave_days,
                      leave_type_id: record?.leave_type_id
                    });
                  }}
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-view" style={{ fontSize: "12px", padding: "2px" }}>
                        {action.label == "Approve" ? action.label : action.label}
                      </Tooltip>
                    }>
                    <> {action.label == "Approve" ? 'Approve' : 'Reject'}
                  </>
                  </OverlayTrigger>

                </button>
              ))}
              </div>
            ) : (
              <div className='d-flex gap-2 mt-2' style={{cursor:record.updated_status === 'pending'?'pointer':'not-allowed'}}>
              {actionStatus.map((action, index) => (
                <button
                disabled={record.updated_status === "pending"? false:true}
                  className={`btn btn-outline-${record.updated_status != "pending"?'text-muted' :action.label == "Approve"
                    ? "success"
                    : "danger"
                  }`}
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("record for id of leave : ", record);
                    attendaceApproval(record.applicant_id, record.appliedfor, record.approval_id, action.label, record.task_id)
                  }}
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-view" style={{ fontSize: "12px", padding: "2px" }}>
                        {action.label == "Approve" ? action.label : action.label}
                      </Tooltip>
                    }><> {action.label == "Approve" ? 'Approve' : 'Reject'}
              </>
                  </OverlayTrigger>
                </button>
              ))}
              </div>
            )}
          </div>
      ),
    },
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const onRowClick = (record) => {
    setSelectedRow(record);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedRow(null);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className={`page-wrapper ${loading ? "position-relative" : ''}`}>
      {/* <div className="page-wrapper"> */}
      <div className="content container-fluid">
        {/* Page Header */}
        {loading ? (
          <div className="d-flex justify-content-center align-items-center h-100 w-100 position-absolute" style={{ zIndex: 9999 }}>
            <Spin size="large" />
          </div>
        ) : <>
          <Breadcrumbs
            maintitle="Task Box"
            title="Dashboard"
            subtitle="Task Box"
          // modal="#add_overtime"
          // name="Add Overtime"
          />

          <div
            className={`modal fade ${isModalOpen ? "show d-block" : ""
              }`}
            id="exampleModalFullscreen"
            tabIndex={-1}
            aria-labelledby="exampleModalFullscreenLabel"
            aria-hidden="true"
            style={{ display: "none", zIndex: "99999" }}
            role="dialog"
          >
            <div className="modal-dialog modal-fullscreen">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="exampleModalFullscreenLabel">
                    Uploaded Attachment
                  </h4>
                  <button
                    style={{
                      color: "red",
                      border: "0px",
                      backgroundColor: "transparent",
                      fontSize: "24px",
                    }}
                    type="button"
                    onClick={() => {
                      setAcknowledge(false);
                      setIsModalOpen(false);
                    }}
                    data-bs-dismiss="modal"
                  >
                    <i class="far fa-window-close"></i>
                  </button>
                </div>
                <div className="modal-body d-flex justify-content-center">
                  <img
                    // style={{ width: "50%",  }}
                    src={`${config.IMAGE_URL}${acknowledgeAttachment}`}
                    alt="attachment"
                  />
                </div>
                <div className="modal-footer">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      gap: "4px",
                    }}
                  >
                    <input
                      style={{ cursor: "pointer" }}
                      type="checkbox"
                      checked={acknowledge}
                      onClick={(e) => {
                        setAcknowledge(e.target.checked);
                      }}
                    />
                    <label>
                      I have reviewed and acknowledge this attachment.
                    </label>
                  </div>

                  {acknowledge && (
                    <div className="dropdown action-label text-center">
                      <Link
                        className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                        to="#"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i
                          className={
                            currentStatus === 1
                              ? "far fa-dot-circle text-info"
                              : currentStatus === 2
                                ? "far fa-dot-circle text-success"
                                : "far fa-dot-circle text-danger"
                          }
                        />{" "}
                        {currentStatus === 1
                          ? "Pending"
                          : currentStatus === 2
                            ? "Approved"
                            : "Rejected"}
                      </Link>

                      <div className="dropdown-menu dropdown-menu-right">
                        <Link className="dropdown-item" to="#" onClick={(e) => e.preventDefault()}>
                          <i className="far fa-dot-circle text-info" /> Pending
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            actionStatusHandler(
                              reimbursementId,
                              "REIMBURSEMENT",
                              2
                            );
                          }}
                        >
                          <i className="far fa-dot-circle text-success" />{" "}
                          Approve
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            actionStatusHandler(
                              reimbursementId,
                              "REIMBURSEMENT",
                              3
                            );
                          }}
                        >
                          <i className="far fa-dot-circle text-danger" /> Reject
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* /Page Header */}
          {/* <div className="row">
            {statsData.map((data, index) => (
              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3" key={index}>
                <div className="stats-info">
                  <h6>{data.title}</h6>
                  <h4>
                    {data.value} <span>{data.month}</span>
                  </h4>
                </div>
              </div>
            ))}
          </div> */}
          {/* /Overtime Statistics */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <SearchBox setPaginationValue={setPaginationValue} />
                <Table
                  className="table-striped"
                  columns={isMobile ? smallScreencolumns : columns}
                  dataSource={leaveElements}
                  rowKey={(record) => record.id}
                  pagination={{ pageSize: paginationValue }}
                  scroll={{ x: true }}
                  tableLayout="auto"
                />
              </div>
            </div>
          </div>
        </>}
      </div>
      {/* /Page Content */}
      {/* </div> */}
      <AddOverTime />
      <DeleteModal Name="Delete Overtime" />

      {selectedRow && <RowDetailModal
        visible={isModalVisible}
        onClose={closeModal}
        record={selectedRow}
        columns={inDetailcolumns}
        title='Task Details'
      />}
      {isModalOpen && <div className="modal-backdrop fade show"></div>}
    </div>
  );
};

export default Taskbox;
