import React from 'react';
import { Card, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import AcknowledgmentPopup from '../../../utils/AcknowledgmentPopup';

const PlanSelection = ({ plans, onSelectPlan, showAlert }) => {
    return (
        <div className="page-wrapper" style={{ position: 'relative', height: '100vh' }}>
        <Container className="my-5">
            <h2 className="text-center mb-4">Choose Your Plan</h2>
            <Row className="g-4">
                {plans.map((plan) => (
                    <Col key={plan.id} md={4}>
                        <Card>
                            <Card.Body>
                                <Card.Title>{plan.title}</Card.Title>
                                <Card.Text>
                                    <ul>
                                        {plan.features.map((feature, index) => (
                                            <li key={index}>{feature}</li>
                                        ))}
                                    </ul>
                                    <h4>{plan.price}</h4>
                                </Card.Text>
                                <Button
                                    variant="primary"
                                    onClick={() => onSelectPlan(plan)}
                                >
                                    Select Plan
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
        {showAlert && (
                <AcknowledgmentPopup 
                popupVariant='success' 
                popupMessage='Payment Successful !' 
                popupIcon="fa-regular fa-circle-check" />
            )}
        </div>
    );
};

export default PlanSelection;
