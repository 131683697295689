import React, { useState } from 'react';
import PlanSelection from './PlanSelection';
import PaymentStep from './PaymentStep';

const SubscriptionPage = () => {
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [currentStep, setCurrentStep] = useState(1);
    const [showAlert, setShowAlert] = useState(false);

    const plans = [
        {
            id: 1,
            title: "2 Months Plan",
            features: ["Access to basic features", "Community support"],
            price: "0",
        },
        {
            id: 2,
            title: "1 Year Plan",
            features: ["Access to premium features", "Priority support"],
            price: "24000",
        },
        {
            id: 3,
            title: "5 Years Plan",
            features: ["Lifetime access", "Dedicated support"],
            price: "108000",
        },
    ];

    const handlePayment = () => {
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 2000);
        setSelectedPlan(null);
        setCurrentStep(1);
    };

    return (
        <>
            {currentStep === 1 && (
                <PlanSelection
                    plans={plans}
                    onSelectPlan={(plan) => {
                        setSelectedPlan(plan);
                        setCurrentStep(2);
                    }}
                    showAlert={showAlert}
                />
            )}

            {currentStep === 2 && (
                <PaymentStep
                    selectedPlan={selectedPlan}
                    onBack={() => setCurrentStep(1)}
                    onPaymentSuccess={handlePayment}
                />
            )}
        </>
    );
};

export default SubscriptionPage;
