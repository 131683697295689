export const companyID = () =>{
    const user = localStorage.getItem("user");
    if(user){
     const companyID = JSON.parse(user);
     const company_id = companyID.company.company_id
     return company_id;
    }
    return null;
}


// export const userLoggedHandler = () =>{
//     const storedCompany = localStorage?.getItem("companyName");
//     const storedCompanyName = storedCompany?.toLowerCase()?.replace(/ /g, "+")

//     const token = localStorage.getItem("token");
//     console.log("App token" , token);
//     if(token){
//       return true;
//     }
//     else{
//         return false;
//     }
// }