import React from "react";
import "../assets/css/myPayHeader.css";

const MyPayHeader = ({ activeHeader, setActiveHeader, setSalary }) => {
  return (
    <div className="mypayheader">
      <nav>
        <ul>
        <li>
            <a
              href="#pay-slips"
              onClick={(e) => {
                e.preventDefault();
                setActiveHeader("paySlips");
              }}
              className={activeHeader === "paySlips" ? "active" : ""}
            >
              PAY SLIPS
            </a>
          </li>
          <li>
            <a
              href="#gross-proration"
              onClick={(e) => {
                e.preventDefault();
                setActiveHeader("grossProration");
                setSalary("");
              }}
              className={activeHeader === "grossProration" ? "active" : ""}
            >
              GROSS PRORATION
            </a>
          </li>
          <li>
            <a
              href="#pay-package"
              onClick={(e) => {
                e.preventDefault();
                setActiveHeader("payPackage");
              }}
              className={activeHeader === "payPackage" ? "active" : ""}
            >
              PAY PACKAGE
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default MyPayHeader;
