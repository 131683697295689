import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import EmployeeLeaveModelPopup from "../../../components/modelpopup/EmployeeLeaveModelPopup";
import SearchBox from "../../../components/SearchBox";
import { getAllLeaves } from "../../../services/leaveService";
import { formatDateInTimezone } from "../../../utils/formatDateInTimezone";
import { getLabelByValue } from "../../../utils/getLabelByValue";
import { format } from "date-fns";
import axios from "axios";
import config from "../../../config";
import "../../../assets/css/employeeLeave.css";
import "../../../assets/css/table.css";
import { companyID } from "../../../auth";
import { formatDecimal } from "../../../utils/formatDecimal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const formatDateForSQL = (date) => {
  return format(date, "yyyy-MM-dd");
};

const EmployeeLeave = () => {

  const location = useLocation();
  const { modalOpen } = location.state || {};

  console.log('modalOpen modalOpen :', location.state)

  const [leaves, setLeaves] = useState([]);
  const [leave, setLeave] = useState({
    employee_id: "",
    start_date: null,
    end_date: null,
    leave_type: "",
    message: "",
    leave_reason: "",
    leave_days: "",
    leave_apply_period: "1",
    first_name: "",
    last_name: "",
  });
  console.log("leaves data from top", leave);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showNames, setShowNames] = useState(false); // New state to control showing names
  const [noOfLeaveForApproval, setNoOfLeaveForApproval] = useState(0);
  const [noOfAppliedLeaves, setNoOfAppliedLeaves] = useState(0);
  const [activeStat, setActiveStat] = useState("Leave applied");
  const [isModalOpen, setIsModalOpen] = useState(modalOpen || false);
  const [deltedtrue, setDeletedTrue] = useState("");
  const [reRenderApprovalData, setReRenderApprovalData] = useState(false);
  const [deletedData, setDeletedData] = useState({});

  const openModal = () => {
    setIsModalOpen(true);
  };

  const employeeId = localStorage.getItem("employeeID");

  const fetchLeaves = async () => {
    try {
      setLoading(true);
      const data = await getAllLeaves(employeeId);
      setNoOfAppliedLeaves(data.length);
      setLeaves(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchLeaves();
  }, []);

  useEffect(() => {
    handlerApprovalStatus();
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    // Cleanup on component unmount
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isModalOpen]);

  const [editedID, setEditedID] = useState("");
  const [previousLeaveDays, setPreviousLeaveDays] = useState(0);

  const editData = (id) => {
    setEditedID(id);
    const data = leaves.filter((item) => item.id === id);
    setPreviousLeaveDays(data[0]?.leave_days);
    setLeave({
      employee_id: data[0].employee_id,
      start_date: formatDateForSQL(data[0].start_date),
      end_date: formatDateForSQL(data[0].end_date),
      leave_type: data[0].leave_type,
      message: data[0].message,
      leave_reason: data[0].leave_reason,
      leave_days: data[0].leave_days,
      leave_apply_period: data[0].leave_apply_period,
      first_name: data[0].first_name || "",
      last_name: data[0].last_name || "",
    });
  };

  const leavetype = [
    { value: "", label: "Select leave type" },
    { value: 1, label: "Sick Leave" },
    { value: 2, label: "Casual Leave" },
    { value: 3, label: "Vacation" },
    { value: 4, label: "Unpaid Leave" },
    { value: 5, label: "Compensatory Off Leave" },
  ];

  const leaveReason = [
    { value: "", label: "Select reason" },
    { value: 1, label: "Illness" },
    { value: 2, label: "Family Emergency" },
    { value: 3, label: "Childcare" },
    { value: 4, label: "Vacation Travel" },
    { value: 5, label: "Personal Reasons" },
    { value: 6, label: "Medical Appointment" },
  ];

  const leavePeriod = [
    { value: 1, label: "Full day" },
    { value: 2, label: "First half" },
    { value: 3, label: "Second half" },
  ];

  const leaveStatus = [
    { value: 2, label: "Approve" },
    { value: 3, label: "Reject" },
  ];



  const leaveElements = leaves.map((leave, index) => ({
    key: leave.id,
    leavetype: getLabelByValue(leavetype, leave.leave_type),
    leave_type_id: leave.leave_type,
    from: formatDateInTimezone(leave.start_date),
    to: formatDateInTimezone(leave.end_date),
    leaveperiod: getLabelByValue(leavePeriod, leave.leave_apply_period),
    noofdays: formatDecimal(leave?.leave_days),
    reason: getLabelByValue(leaveReason, leave.leave_reason),
    status: leave.leave_status,
    first_name: leave.first_name,
    last_name: leave?.last_name,
    updated_status: leave?.updated_status,
    photo: leave?.photo,
    employee_id: leave?.employee_id,
    is_editable: leave?.is_editable
  }));

  console.log("leave Elements", leaveElements);

  const confirmDelete = () => {
    if (deltedtrue) {
      axios
        .put(`${config.API_BASE_URL}delete-leave/${deltedtrue}`, deletedData)
        .then((res) => {
          console.log(res);
          fetchLeaves();
        })
        .catch((err) => console.log(err));
    }
  };

  const columns = [
    {
      title: "Leave Type",
      dataIndex: "leavetype",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.leavetype.length - b.leavetype.length,
    },
    {
      title: "From",
      dataIndex: "from",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.from.length - b.from.length,
    },
    {
      title: "To",
      dataIndex: "to",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.to.length - b.to.length,
    },
    {
      title: "Leave period",
      dataIndex: "leaveperiod",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.leaveperiod.length - b.leaveperiod.length,
    },
    {
      title: "No Of Days",
      dataIndex: "noofdays",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },
    {
      title: "Reason",
      dataIndex: "reason",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.reason.length - b.reason.length,
    },
    ...(showNames
      ? [
        {
          title: "Status",
          dataIndex: "updated_status",
          render: (text, record) => (
            <span
              style={{
                color:
                  record.updated_status === 1
                    ? "#e0dd24"
                    : record.updated_status === 2
                      ? "green"
                      : "red",
              }}
            >
              {record.updated_status === 1
                ? "Pending"
                : record.updated_status === 2
                  ? "Approved"
                  : "Rejected"}
            </span>
          ),
          sorter: (a, b) => a.status.length - b.status.length,
        },
      ]
      : [
        {
          title: "Status",
          dataIndex: "status",
          render: (text, record) => (
            <span
              style={{
                color:
                  record?.status == 1
                    ? "#e0dd24"
                    : record?.status == 2
                      ? "green"
                      : "red",
              }}
            >
              {
                record?.status == 1
                  ? "Pending"
                  : record?.status == 2
                    ? "Approved"
                    : "Rejected"}
            </span>
          ),
          sorter: (a, b) => a.status.length - b.status.length,
        },
      ]),
    {
      title: "Action",
      className: "text-end",
      sorter: false,
      render: (record) => (
        <div className="action-menu text-end">
          {console.log(
            "records from",
            record?.status != 1
              ? !showNames && record?.is_editable != 0
                ? "not-allowed--p"
                : "pointer"
              : "pointer--n"
          )}

          {/* Render options directly without a dropdown */}
          {!showNames ? (
            <div className="d-flex gap-2 mt-2" style={{ cursor: record?.is_editable == 0 ? "pointer" : "not-allowed" }}>
              <button
                disabled={record?.is_editable == 0 ? false : true}
                className="list-group-item list-group-item-action"
                onClick={() => {
                  editData(record.key);
                  openModal();
                }}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-view" style={{ fontSize: "12px", padding: "2px" }}>
                      Edit leave
                    </Tooltip>
                  }
                >
                  <i className={`fa fa-pencil me-2 ${record?.is_editable != 0 ? 'text-muted' : 'text-info'}`} />
                </OverlayTrigger>
              </button>
              <button
                disabled={record?.is_editable == 0 ? false : true}
                className="list-group-item list-group-item-action text-danger"
                data-bs-toggle="modal"
                data-bs-target="#delete_employee"
                onClick={() => {
                  setDeletedTrue(record.key);
                  setDeletedData({
                    employee_id: record?.employee_id,
                    leave_type: record?.leave_type_id,
                    leave_days: record?.noofdays,
                  });
                }}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-view" style={{ fontSize: "12px", padding: "2px" }}>
                      Delete leave
                    </Tooltip>
                  }
                >
                  <i className={`fa fa-trash me-2 ${record?.is_editable != 0 ? 'text-muted' : 'text-danger'}`} />
                </OverlayTrigger>
              </button>
            </div>
          ) : (
            <div className="d-flex gap-2 mt-2" style={{ cursor: showNames ? record?.updated_status == 1 ? "pointer" : "not-allowed" : record?.is_editable == 0 ? "pointer" : "not-allowed" }}>
              {leaveStatus.map((leave, index) => (
                <button
                  disabled={record?.updated_status == 1 ? false : true}
                  className={`list-group-item list-group-item-action ${leave.label == "Approve" ? "text-success" : "text-danger"
                    }`}
                  key={index}
                  onClick={() => {
                    leaveStatusIdHandler(
                      leave.value,
                      record.key,
                      record?.noofdays,
                      record?.leave_type_id,
                      record?.employee_id
                    );
                  }}
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-view" style={{ fontSize: "12px", padding: "2px" }}>
                        {leave.label == "Approve" ? 'Approve leave' : 'Reject leave'}
                      </Tooltip>
                    }
                  >
                    <i
                      className={`${leave.label == "Approve" ? 'far fa-check-circle' : 'far fa-times-circle'} me-2 ${record.updated_status != 1?'text-muted' : leave.label == "Approve" ? "text-success" : "text-danger"
                        }`}
                    />
                  </OverlayTrigger>
                  {/* {leave.label} */}
                </button>
              ))}
            </div>
          )}
        </div>

      ),
    },
  ];

  // Conditionally add first name and last name columns if showNames is true
  if (showNames) {
    columns.unshift(
      {
        title: "First Name",
        dataIndex: "first_name",
        render: (text) => <span>{text}</span>,
        sorter: (a, b) => a.first_name.length - b.first_name.length,
      },
      {
        title: "Last Name",
        dataIndex: "last_name",
        render: (text) => <span>{text}</span>,
        sorter: (a, b) => a.last_name.length - b.last_name.length,
      }
    );
  }

  const leaveStats = [
    {
      id: 1,
      title: "Leave applied",
      value: noOfAppliedLeaves,
    },
    // {
    //   id: 2,
    //   title: "Medical Leave",
    //   value: 3,
    // },
    // {
    //   id: 3,
    //   title: "Other Leave",
    //   value: 4,
    // },
    // {
    //   id: 4,
    //   title: "Remaining Leave",
    //   value: 5,
    // },
    {
      id: 5,
      title: "Leave For Approval",
      value: noOfLeaveForApproval,
    },
  ];

  const handlerApprovalStatus = async (approve) => {
    setLeaves([]);
    try {
      const employeeID = localStorage.getItem("employeeID");
      const response = await axios.get(
        `${config.API_BASE_URL}employeseApproval/${employeeID}`
      );
      const approvalData = response.data.result;

      const response2 = await axios.get(
        `${config.API_BASE_URL}leave-for-approval/${employeeID}`
      );
      const approvalData2 = response2.data.result;
      console.log("approvalData", approvalData);
      if (approve === "Leave For Approval") {
        let combinedData = [];

        if (approvalData.length > 0) {
          combinedData = [...combinedData, ...approvalData];
        }
        if (approvalData2.length > 0) {
          combinedData = [...combinedData, ...approvalData2];
        }

        if (combinedData.length > 0) {
          console.log("combinedData combinedData :", combinedData);
          setLeaves(combinedData);
        }

        setActiveStat("Leave For Approval");
        console.log("leaves data", approvalData);
        setShowNames(true);
      } else {
        setShowNames(false);
        fetchLeaves();
        let combinedData = [];

        if (approvalData.length > 0) {
          combinedData = [...combinedData, ...approvalData];
        }
        if (approvalData2.length > 0) {
          combinedData = [...combinedData, ...approvalData2];
        }
        setNoOfLeaveForApproval(combinedData.length);
        setActiveStat("Leave applied");
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  // useEffect(()=>{
  //   handlerApprovalStatus("Leave For Approval")

  // },[reRenderApprovalData])
  //---------------------------- handling Leave Status---------------------------------------
  const company_id = companyID();

  const leaveStatusIdHandler = async (id, leave_id, no_of_days, leave_type_id, leave_employee_id) => {
    setReRenderApprovalData(false);
    console.log("leave status id ----->", leave.employee_id);
    try {
      const response = await axios.post(
        `${config.API_BASE_URL}leave/${leave_id}/approve/`,
        {
          company_id: company_id,
          approver_id: employeeId,
          status_id: id,
          no_of_days: no_of_days,
          leave_type_id: leave_type_id,
          leave_employee_id: leave_employee_id
        }
      );
      console.log("leaveStatusIdHandler : response : ", response);
      if (response) {
        setLeaves((prevData) =>
          prevData.map((leave) =>
            leave.leave_id === leave_id ? { ...leave, status_id: id } : leave
          )
        );
        setReRenderApprovalData(true);
        handlerApprovalStatus("Leave For Approval");
      }
    } catch (error) {
      console.error("Error updating leave:", error);
    }
  };



  // const fetchLeaveTypeData = async()=>{
  //   const company_id = companyID();
  //   try{
  //     const response = await axios(`${config.API_BASE_URL}leaveType/${company_id}`);
  //     console.log("leave response type" ,response )
  //     setLeaveTypeData(response?.data?.result);
  //   }
  //   catch(err){
  //     console.log(err);

  //   }
  // }

  useEffect(() => {
    return () => {
      // fetchLeaveTypeData();
      setReRenderApprovalData(false);
    };
  }, []);



  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Leaves"
            title="Dashboard"
            subtitle="Leaves"
            // modal="#add_leave"
            name="Apply Leave"
            openModal={openModal}
          />

          <div
            className="row d-flex"
          >
            {leaveStats.map((stat, index) => (
              <div
                className="col-md-6 col-sm-6 col-lg-6 col-xl-3"
                key={index}
                onClick={() => {
                  handlerApprovalStatus(stat.title);
                }}
              >
                <div
                  className="stats-info"
                  style={{
                    cursor: "pointer",
                    backgroundColor: stat.title === activeStat ? "orange" : ""
                  }}
                >
                  <h6 style={{
                    color: stat.title === activeStat ? "white" : ""
                  }}>{stat.title}</h6>
                  <h4 style={{
                    color: stat.title === activeStat ? "white" : ""
                  }}>{stat.value}</h4>
                </div>
              </div>
            ))}
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <SearchBox />
                <Table
                  columns={columns}
                  dataSource={leaveElements?.length > 0 ? leaveElements : []}
                  className="table-striped"
                  scroll={{ x: true }}
                  tableLayout="auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Add Leave Modal */}
      <EmployeeLeaveModelPopup
        leave={leave}
        setLeave={setLeave}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        editedID={editedID}
        previousLeaveDays={previousLeaveDays}
        setEditedID={setEditedID}

      />
      {/* Add Leave Modal */}
      {/* Delete Modal */}
      <DeleteModal Name="Delete Leaves" confirmDelete={confirmDelete} />
      {isModalOpen && <div className="modal-backdrop fade show"></div>}

      {/* Delete Modal */}
    </>
  );
};

export default EmployeeLeave;
