import React, { useEffect, useState } from "react";
import { Select, Table, message, Empty } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import "../assets/css/paySlips.css";
import config from "../config";
import { companyID } from "../auth";
import { calculateSalaryBreakdown } from "../utils/calculateSalaryBreakdown";
import { formatDecimal } from "../utils/formatDecimal";
import { saveAs } from "file-saver";
import { convertDateFormate } from "../utils/convertDateFormate";

const { Option } = Select;

function PaySlips({salaryConfig}) {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const [financialYear, setFinancialYear] = useState(currentYear);
  const [salaryData, setSalaryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [employeeSalary,setEmployeeSalary]=useState(0);
  const companyId = companyID(); 
  const employeeId = localStorage.getItem("employeeID");
  const [grossProration, setGrossProration] = useState({});

  const columns = [
    {
      title: "Month",
      dataIndex: "month",
      key: "month",
      render: (text) => {
        const monthYear = convertDateFormate(text)
        return new Date(monthYear.year,monthYear.month - 1).toLocaleString("default", { month: "long" })
      },
    },
    {
      title: "Total Salary",
      dataIndex: "total_salary",
      key: "total_salary",
      render:(text)=> formatDecimal(text)
    },
    {
      title: "Reimbursement",
      dataIndex: "reimbursement_allowance",
      key: "reimbursement_allowance",
      render:(text)=> formatDecimal(text)
    },
    {
      title: "Other deduction",
      dataIndex: "other_deduction",
      key: "other_deduction",
      render:(text)=> formatDecimal(text)
    },
    // {
    //   title: "Other Deduction Reason",
    //   dataIndex: "other_deduction_reason",
    //   key: "other_deduction_reason",
    //   render:(text)=> (text || '-')
    // },
    {
      title: "Total Deduction",
      dataIndex: "total_deduction",
      key: "total_deduction",
      render:(text)=> formatDecimal(text)
    },
    {
      title: "Net Salary",
      dataIndex: "net_salary",
      key: "net_salary",
      render:(text)=> formatDecimal(text)
    },
    
    // {
    //   title: "Company holidays",
    //   dataIndex: "total_holidays",
    //   key: "total_holidays",
    //   render:(text)=> formatDecimal(text)
    // },
    // {
    //   title: "Salary Eligible Days",
    //   dataIndex: "salary_eligible_days",
    //   key: "salary_eligible_days",
    //   render:(text)=> formatDecimal(text)
    // },
    // {
    //   title: "Net Salary",
    //   dataIndex: "net_salary",
    //   key: "net_salary",
    //   render:(text)=> formatDecimal(text)
    // },
    {
      title: "Action",
      dataIndex: "salary_slip",
      key: "payslip",
      render: (text, record) => {
         return <Link to="#" className="btn btn-sm btn-primary" onClick={(e) => {
          e.preventDefault();
             setLoading(true)
             axios.get(`${config.API_BASE_URL}${text}`,{
              responseType: "blob"
            })
             .then((res)=>{
              const pdfBlob = new Blob([res.data], { type: "application/pdf" });
              const pdfUrl = URL.createObjectURL(pdfBlob);
              // if (option === "view") 
                // window.open(pdfUrl, "_blank");
              // else
                saveAs(pdfBlob,text);
             })
             .catch((err)=>{
              console.log(err)
             })
             .finally(()=>{
              setLoading(false)
             })
          }}>
          View Slip
        </Link>
        // const dailySalary = employeeSalary / record.total_working_days;
        // const salary = dailySalary * record.salary_eligible_days;

        // const roundedSalary = Math.round((salary + Number.EPSILON) * 100) / 100;
    
        // return <>{roundedSalary}</>;
      },
    },
    
  ];

  // useEffect(() => {
  //   fetchSalaryData();
  // }, [financialYear]);

//   useEffect(() => {
//     axios
//       .get(
//         `${config.API_BASE_URL}employees/companies/${companyId}/employees/${employeeId}/salary-breakdown`,{
//           params:{month :'2024-11'}
//         }
//       )
//       .then((response) => {
//         console.log("response config ", response.data.config);
//         // setSalaryConfig(response.data.config);
//         setEmployeeSalary(response?.data?.employmentData[0]?.salary);
//         setGrossProration(calculateSalaryBreakdown(response?.data?.employmentData[0]?.salary,salaryConfig));
//         // console.log("response calculateSalaryBreakdown ", calculateSalaryBreakdown(response?.data?.employmentData[0]?.salary,salaryConfig));

//       })
//       .catch((error) => {
//         console.log(error)
//       });
// }, []);

  useEffect(() => {
    setLoading(true);
      axios
        .get(
          `${config.API_BASE_URL}salary/${employeeId}/${financialYear}`
        )
        .then((response) => {
          console.log("response config ", response.data.data);
          setSalaryData(response.data.data);
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            setSalaryData([]);
        } else {
            console.error(error);
        }
        })
        .finally(()=>{
          setLoading(false);
        });
  }, [financialYear]);

  const handleFinancialYearChange = (value) => {
    setFinancialYear(value);
  };

  // const fetchSalaryData = async () => {
  //   setLoading(true);
  //   try {
  //     // const monthsToShow = financialYear == currentYear ? currentMonth-1 : 12;
  //     const monthsToShow = 12;
  //     const monthlyData = [];

  //     // console.log('financialYear : ',financialYear, ' currentYear : ',currentYear, 'monthsToShow : ',monthsToShow, "currentMonth : ",currentMonth);

  //     for (let month = 1; month <= monthsToShow; month++) {
      
  //       const workingDaysResponse = await axios.get(`${config.API_BASE_URL}working-days/${companyId}/${month}/${financialYear}`);
  //       const totalWorkingDays = workingDaysResponse.data.total_working_days;

  //       const salaryResponse = await axios.post(`${config.API_BASE_URL}calculate-salary`, {
  //         employee_id: employeeId,
  //         company_id: companyId,
  //         month,
  //         year: financialYear,
  //       });

  //       if (salaryResponse.data.salaryDetails.length === 0) {
  //         // message.warning("Salary details not found for the selected employee and company.");
  //         // monthlyData.push({
  //         //   month,
  //         //   total_working_days: totalWorkingDays,
  //         //   ...{
  //         //     "employee_id": 1,
  //         //     "company_id": 1,
  //         //     "salary_eligible_days": 0,
  //         //     "total_attendance": 0,
  //         //     "total_leave": 0,
  //         //     "total_holidays": 0
  //         // },
  //         // });
  //         continue;
  //       }

  //       const salaryDetails = salaryResponse.data.salaryDetails[0];
  //       if(salaryDetails?.total_attendance!=0 || salaryDetails?.total_leave!=0){monthlyData.push({
  //         month,
  //         total_working_days: totalWorkingDays,
  //         ...salaryDetails,
  //       });}
  //     }

  //     setSalaryData(prevData=>([...prevData,...monthlyData]));
  //   } catch (error) {
  //     message.error("Error fetching salary data.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const handleGenerateSlip = (record) => {
  //   // Handle the payslip generation logic here

  //   console.log("Generate payslip for:", record);
  // };

  // const generateSalarySlip=(employeeRecord)=>{
  //   setLoading(true)
  //   axios.post(`${config.API_BASE_URL}generate-pdf`,{
  //     ...grossProration,
  //     ...employeeRecord,
  //     employee_id:employeeId
  //   })
  //   .then((res)=>{
  //   const { fileName } = res?.data?.result;
  //   axios.get(`${config.API_BASE_URL}download-pdf`,{
  //     params: { fileName: fileName },
  //     responseType: "blob"
  //   })
  //   .then((res) => {
  //     const pdfBlob = new Blob([res.data], { type: "application/pdf" });
  //     const pdfUrl = URL.createObjectURL(pdfBlob);
  //     // if (option === "view") 
  //       // window.open(pdfUrl, "_blank");
  //     // else
  //       saveAs(pdfBlob,fileName);
  //   })
  //   })
  //   .catch((error)=>{
  //    console.log(error);
  //   })
  //   .finally(()=>{
  //     setLoading(false)
  //   })
  // }

  return (
    <div className="pay-slips">
      <h2>Pay slips for {financialYear}</h2>
      <div className="financialYearDropdown mb-2">
        <label>Select Financial Year:</label>
        <Select value={financialYear} style={{ width: 120 }} onChange={handleFinancialYearChange}>
          <Option value="2023">2023</Option>
          <Option value="2024">2024</Option>
          <Option value="2025">2025</Option>
          {/* <Option value="2025-26">2025-26</Option> */}
        </Select>
      </div>
      <div className="row">
        <div className="table-responsive">
          {salaryData.length > 0 ? (
            <Table
              dataSource={salaryData}
              columns={columns}
              rowKey="month"
              loading={loading}
              pagination={false}
            />
          ) : (
            !loading ? <Empty description="No salary details available." />:<Empty description="Fetching salary details..." />
          )}
        </div>
      </div>
    </div>
  );
}

export default PaySlips;
