import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../config";
import { companyID } from "../auth";
import EmployeeSalaryToExcel from "./EmployeeSalaryToExcel";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const SearchBox = ({setPaginationValue, setListView, listView, setShowAcknowledgmentPopup, setAcknowledgmentPopup}) => {

  const [show, setShow] = useState(false);
  const[employeesSalary, setEmployeesSalary] = useState([]);
  const[error,setError]=useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const company_id  = companyID();

  const fetchEmployeesSalary=(month)=>{
    axios.get(`${config.API_BASE_URL}companies/${company_id}/departments/employees/salaries?month=${month}`)
    .then((res)=>{
      setEmployeesSalary(res.data.data);
      setError(prev=>({...prev,salaryDetails:null}))
    })
    .catch((error)=>{
      if (error.response && error.response.status === 404) {
        setEmployeesSalary([])
        setError(prev=>({...prev,salaryDetails:error.response.data.message}))
      }
      console.log(error)
    })
    .finally(()=>{
      //loader
    })
  };

  useEffect(()=>{
  const date = new Date();
  const year = date.getFullYear(); 
  const month = String(date.getMonth() + 1).padStart(2, '0'); 

  fetchEmployeesSalary(`${year}-${month}`);
  },[show])

  const downloadExcel =()=>{

    handleShow();

  }

  return (
    <>
      <div className={`row ${setListView ? 'mb-2': ''}`}>
        <div className="col-sm-12 col-md-6">
          <div className="dataTables_length d-flex">
            <label className="d-flex">
              Show{" "}
              <select
                style={{cursor:'pointer'}}
                name="DataTables_Table_0_length"
                aria-controls="DataTables_Table_0"
                className="custom-select custom-select-sm form-control form-control-sm me-1 ms-1 mb-2"
                onChange={(e)=> setPaginationValue(e.target.value)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>{" "}
              entries
            </label>
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
        {setListView && <div className="view-icons">
          <Link 
            onClick={(e)=>{
              e.preventDefault()
              downloadExcel()
            }}
            className={`btn btn-link`}>
            <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-view" style={{ fontSize: "12px", padding: "2px" }}>
                      Download Salary Data
                    </Tooltip>
                  }
                >
           <i className="fa fa-receipt" style={{textDecorationLine:'underline', cursor:'pointer'}}></i>
           </OverlayTrigger>
           </Link>
            <Link
              onClick={(e)=>{
                e.preventDefault()
                setListView(false)
              }}
              className={`grid-view btn btn-link ${!listView ? 'active':''} mx-2`}
            >
              <i className="fa fa-th" />
            </Link>
            <Link 
            onClick={(e)=>{
              e.preventDefault()
              setListView(true)
            }}
            className={`list-view btn btn-link ${listView ? 'active':''}`}>
              <i className="fa fa-bars" />
            </Link>
          </div>}
        </div>
      </div>
      <EmployeeSalaryToExcel
      show={show}
      handleClose={handleClose}
      employeesSalary={employeesSalary}
      fetchEmployeesSalary={fetchEmployeesSalary}
      error={error}
      setError={setError}
      setAcknowledgmentPopup={setAcknowledgmentPopup} setShowAcknowledgmentPopup={setShowAcknowledgmentPopup}
      />
    </>
  );
};

export default SearchBox;
