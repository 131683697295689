import React, { useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import config from "../../../config";
import { useLocation, useNavigate } from "react-router-dom";

const AttendanceApprovalForm = ({attendanceApprovalData,isModalOpen,setIsModalOpen}) => {     

  const reasonType = [
    { value: 1, label: 'Not in location issue'},
    { value: 2, label: 'App not loading properly'},
    { value: 3, label: 'Forgot to login in app'},
    { value: 4, label: 'Forgot to logout from app'},
    { value: 5, label: 'New Joinee Registration Process Not Completed'},
    { value: 6, label: 'Weather-related delay'},
    { value: 7, label: 'Public transport delays.'},
    { value: 8, label: 'Traffic congestion or road closure.'},
    { value: 9, label: 'Unexpected personal emergencies'},
    { value: 10, label: 'Network outage.'},
    { value: 11, label: 'Other.'}
  ];

  const [approvalForm, setApprovalForm]= useState({});
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  console.log(' dropDown : ',approvalForm, 'errors : ',errors, ' final data : ',{
    ...approvalForm,
        employee_id: attendanceApprovalData?.employeeId,
        attendance_id: attendanceApprovalData?.employeeId,
        attendance_for_approval: attendanceApprovalData?.Date,
        status: "pending"
    })

  const handleOnChange = (option, key) => {
    setApprovalForm((prev) => ({
      ...prev,
      [key]: option?.value || "",
    }));

    setErrors((prev) => ({
      ...prev,
      [key]: option?.value ? "" : `${key} is required`,
    }));
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const applyForApproval=async()=>{

    if(!approvalForm.reason || !approvalForm.message){
        setErrors((prev) => ({
            ...prev,
            ['apply']: `All fields are required`,
          }));
        return;
    }
    else{
        setErrors((prev) => ({
            ...prev,
            ['apply']: '',
          }));
    }

    axios.post(`${config.API_BASE_URL}api/attendance/createAttendanceApproval`,{
        ...approvalForm,
        employee_id: attendanceApprovalData?.employeeId,
        attendance_id: attendanceApprovalData?.attendance_id,
        attendance_for_approval: attendanceApprovalData?.Date,
        status: "pending"
    })
    .then((res)=>{
        setErrors({});
        if(res.data.success==true){
            //show applied message
            closeModal();
        }
    })
    .catch(err=>{
     console.log(err);
     if(err.response.data.error_code=='E01'){
        setErrors((prev) => ({
            ...prev,
            ['apply']: err.response.data.message,
          }));
     }
    })
    .finally(()=>{
    // loader
    })

  }

  

  const closeModal=()=>{
    setIsModalOpen(false);
    setApprovalForm({});
    setErrors({});
    navigate(`../${location.pathname}`);
  }

  return (
    <>
      <div id="add_leave" className={`modal custom-modal fade ${
          isModalOpen ? "show d-block" : ""
        }`}role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Apply For Attendance Approval</h5>
              <button
                type="button"
                className="btn-close"
                onClick={()=>{
                    closeModal();
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                
                <div className="input-block mb-3">
                  <label className="col-form-label fs-6">
                    For Date : {attendanceApprovalData?.Date}
                  </label>
                </div>
                <div className="input-block mb-3 d-flex gap-2 justify-content-between">
                  <label className="col-form-label fs-6">
                    Punch In At : {attendanceApprovalData?.PunchIn}
                  </label>
                  <label className="col-form-label fs-6">
                    Punch Out At : {attendanceApprovalData?.PunchOut}
                  </label>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label fs-6">
                   Total hours : {attendanceApprovalData?.TotalHours}
                  </label>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Reason <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={reasonType}
                    placeholder="Select Reason"
                    styles={customStyles}
                    onChange={(option) => handleOnChange(option, 'reason')}
                  />
                  {errors['reason'] && <p style={{ color: "red" }}>* {errors['reason']}</p>}
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Message <span className="text-danger">*</span>
                  </label>
                  <textarea
                    rows={4}
                    className="form-control"
                    defaultValue={""}
                    value={approvalForm?.message}
                    onChange={(e)=>{
                        handleOnChange({value:e.target.value}, 'message')
                    }}
                  />
                  {errors['message'] && <p style={{ color: "red" }}>* {errors['message']}</p>}
                </div>
                {errors['apply'] && <p style={{ color: "red" }}>* {errors['apply']}</p>}
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="reset"
                    onClick={()=>applyForApproval()}
                    disabled={!approvalForm.reason || !approvalForm?.message?.trim() ? true:false}
                  >
                    Apply
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AttendanceApprovalForm;
