import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { exportToExcel } from "../utils/exportToExcel";

const EmployeeSalaryToExcel = ({show,handleClose,employeesSalary,fetchEmployeesSalary,error,setError, setShowAcknowledgmentPopup, setAcknowledgmentPopup}) => {

const [selectedDate, setSelectedDate] = useState(new Date());

useEffect(()=>{
  setSelectedDate(new Date());
},[show])

const hideAcknowledgmentPopup = () => {
  const resetAcknowledgmentPopupData = ()=>{
    setShowAcknowledgmentPopup(false);
    setAcknowledgmentPopup({
      popupVariant:'', 
      popupMessage:'', 
      popupIcon:''
  })
  }
  setTimeout(() => resetAcknowledgmentPopupData() 
    , 2000); 
};

  const handleDownload = () => {
    const data = employeesSalary.map((item) => ({
        Name: `${item.first_name} ${item.middle_name || ""} ${item.last_name}`.trim(),
        "Month": item.month,
        "Net Salary": item.net_salary,
        "Account number":item.account_number,
        "Bank name":item.bank_name,
        "IFSC code":item.ifsc_code,
        "Branch name":item.branch_name,
        "Phone Number":item?.number
      }));
      const year = selectedDate.getFullYear(); 
      const month = String(selectedDate.getMonth() + 1).padStart(2, '0'); 
      exportToExcel(data,`Salary Details-${`${year}-${month}`}`)
      .then((successMessage) => {
        setShowAcknowledgmentPopup(true);
        setAcknowledgmentPopup({
          popupVariant:'success', 
          popupMessage:successMessage, 
          popupIcon:'fa-regular fa-circle-check'
        });
      })
      .catch((errorMessage) => {
        setShowAcknowledgmentPopup(true);
        setAcknowledgmentPopup({
          popupVariant:'danger', 
          popupMessage:errorMessage, 
          popupIcon:'fa-regular fa-circle-xmark'
        })
      })
      .finally(()=>
        { 
          handleClose()
          hideAcknowledgmentPopup();
        }
        )

  };
  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>
        <div className="d-flex gap-2" style={{alignItems:'baseline'}}>
           <p>Select</p>
            <DatePicker
              selected={selectedDate}
              onChange={(date) => {
                const year = date.getFullYear(); 
                const month = String(date.getMonth() + 1).padStart(2, '0'); 
                setSelectedDate(date);
                fetchEmployeesSalary(`${year}-${month}`)
              }}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              className="form-control"
            />
          </div>
          </Modal.Title>
        </Modal.Header>
        {error?.salaryDetails ? <Modal.Body>
            <p className="text-danger">* {error?.salaryDetails}</p>
          </Modal.Body>:<></>}
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{
            handleClose();
            setError(prev=>({...prev,salaryDetails:null}));
          }}>
            Close
          </Button>
          <Button variant="success" disabled={employeesSalary.length <= 0? true:false} onClick={handleDownload}>
            Download Salary Data
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EmployeeSalaryToExcel;
