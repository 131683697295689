import React, { useEffect, useState } from "react";
import Select from "react-select";
import { getData, postData } from "../../utils/api";
import axios from "axios";
import config from "../../config";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import createCustomSchema from "../../fieldValidator/fieldValidator";
import { companyID } from "./../../auth";

const AddandEditPolicy = ({ policy, setPolicyData, selectedDesignationtId , selectedDepartmentId, isModalOpen,setIsModalOpen, isEditModalOpen, setIsEditModalOpen }) => {
  const company_ID = companyID();
  const employeeID = localStorage.getItem("employeeID");
  const location = useLocation();
  const navigate = useNavigate();
  // console.log("policy ," ,policy)
  const [setselectOne] = useState(null);
  const [setselectTwo] = useState(null);
  const [policyFile, setPolicyfile] = useState({});
  const [policyCategoryID, setPolicyCategoryID] = useState(1);
  const [policyCategory, setPolicyCategory] = useState([]);
  const [policyCategoryName, setPolicyCategoryName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  //---------------------Text Editor -------------------------



  const [text, setText] = useState("");

  console.log("text--------->", text);

  console.log("text", text);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  //---------------------policuices---------------

  let token = localStorage.getItem("token");
  const configuration = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const [policyDetails, setPolicyDetails] = useState({
    policy_name: "",
    generate_policy: "",
    policy_category_id: "",
    policy_file: null,
  });

  const validationSchema = Yup.object({
    policy_name: createCustomSchema("text", {
      message: "Enter Policy Name!",
      min: 3,
    }),

    policy_category_id: createCustomSchema("dropdown", {
      message: "Select  Policy Category !",
    }),
    policy_file: createCustomSchema("file", {
      message: "Select Policy File!",
    }),
  });

  const [editorContent, setEditorContent] = useState("");

  const handleEditorChange = (content) => {
    console.log("content", content);
    setEditorContent(content);
    if(content=='<p><br></p>'){
      setPolicyDetails({ ...policyDetails, generate_policy: '' });
      setEditorContent('');
      return
    }
    setPolicyDetails({ ...policyDetails, generate_policy: content });
  };

  const handleTextChange = (content) => {
    setText(content);
    console.log("yup error : content : ", content);
    if(content=='<p><br></p>'){
      setPolicyDetails({ ...policyDetails, generate_policy: '' });
      setEditorContent('');
      return
    }
    setPolicyDetails({ ...policyDetails, generate_policy: content });
  };
  console.log(" policyDetails nit : ", policyDetails);

  const [policyDetailsError, setPolicyDetailsError] = useState({});

  const policyHandler = async (e) => {
    const { name, value } = e.target;
    setPolicyDetails({ ...policyDetails, [name]: value });

    const newPolicyDetails = {
      ...policyDetails,
      [name]: value,
    };

    try {
      await validationSchema.validateAt(name, newPolicyDetails);
      setPolicyDetailsError((prevErrors) => ({ ...prevErrors, [name]: "" }));
    } catch (err) {
      setPolicyDetailsError((prevErrors) => ({
        ...prevErrors,
        [name]: err.message,
      }));
    }
  };
  const policyData = async (e) => {
    console.log("calling------------------");
    try {
      const res = await getData("hr");
      console.log("data respinse receiver-->", res);
      const filteredData = res?.data?.results?.filter(
        (policy) => policy?.status === 1
      );
      setPolicyData(filteredData);
    } catch (err) {
      console.log(err);
    }
  };

  const [insertedPolicyID , setInsertedPolicyID] = useState("");

 

  useEffect(() => {
    if (policy) {
      setPolicyDetails({ ...policy[0] });
    }
    // console.log(policyDetails  ,"therese wjxhwjg")
  }, [policy]);

  //--------------------------edit policy--------------------

  // useEffect(()=>{
  //   const policyData = async(e) =>{
  //     const res = await getData("hr/policy-data/");
  //     console.log("data respinse receiver-->" , res);
  //     policyHandler(res?.data?.results);
  //   }
  //   policyData();
  // },[])

  const policyHandlerFile = (e) => {
    setPolicyDetails({ ...policyDetails, policy_file: e.target.files[0] });
  };

  const policyEditSubmitHandler = async (e) => {
    e.preventDefault();

    console.log("edited policy--->", policyDetails);
    //  console.log("all data from edit--->" ,policyDetails);

    try {
      const policyHandling = {
        policy_name: validationSchema.fields.policy_name,
        policy_category_id: validationSchema.fields.policy_category_id,
      };

      const dynamicSchema = Yup.object().shape(
        Object.keys(policyDetails).reduce((acc, key) => {
          // Include mandatory fields
          if (policyHandling[key]) {
            acc[key] = policyHandling[key];
          } else if (policyDetails[key]) {
            // Dynamically include other filled fields
            acc[key] = validationSchema.fields[key];
          }
          return acc;
        }, {})
      );

      await dynamicSchema.validate(policyDetails, {
        abortEarly: false,
      });
      // if( !policyDetails?.policy_category_id || !policyDetails?.generate_policy || !policyDetails.policy_name ){
      //   console.log("data not saved")
      //   return ;
      //  }
      const formData = new FormData();
      console.log("edited policy details " , policyDetails)
      for (const key in policyDetails) {
        if (key != "updated_by") {
          formData.append(key, policyDetails[key]);
        }
      }
      formData.append("departments" , selectedDepartments?.filter(department=>department!==null));
      formData.append("designations" ,selectedDesignations?.filter(designation=>designation!==null));
      formData.append("updated_by", employeeID);
      const res = await axios.put(
        `${config.API_BASE_URL}hr/upload-policy/${policyDetails?.id}`,
        formData,
        configuration
      );
      console.log("updated data response --->", res);
      if (res?.data?.succee === true) {
        navigate(`${location?.pathname}`);
        policyData();
        setErrorMessage("");
        closeEditModal();
        // setUpdateGrid(true);
      }
    } catch (error) {
      console.log(error);
      const newError = {};
      error?.inner?.forEach((err) => {
        newError[err.path] = err.message;
      });
      setPolicyDetailsError(newError);
      console.log("yup error : ", newError);
    }
  };
  //----------------- all fetched data--------------------

  //------------- get all category policy---------------
  useEffect(() => {
    try {
      const fetchData = async () => {
        const { data } = await axios.get(
          `${config.API_BASE_URL}hr/policy-category`,
          configuration
        );
        setPolicyCategory(data?.results);
      };
      fetchData();
    } catch (err) {
      console.log("error", err);
    }
  }, []);
  const policyCateroryHandler = async (e, id) => {
    setPolicyDetails({ ...policyDetails, policy_category_id: id });
    setPolicyCategoryName(e.target.value);

    const newPolicyDetails = {
      ...policyDetails,
      policy_category_id: id,
    };

    try {
      await validationSchema.validateAt("policy_category_id", newPolicyDetails);
      setPolicyDetailsError((prevErrors) => ({
        ...prevErrors,
        ["policy_category_id"]: "",
      }));
    } catch (err) {
      setPolicyDetailsError((prevErrors) => ({
        ...prevErrors,
        ["policy_category_id"]: err.message,
      }));
    }
  };

  // console.log('policyCategoryID state: ',policyCategoryID)

  //----------------------------  --------------------------------//
  const [department, setDepartment] = useState([]);
  const configuer = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    axios
      .get(`${config.API_BASE_URL}department/${company_ID}`, configuer)
      .then((res) => {
        // const departments = res.data.result.map((dept) => ({
        //   value: dept.id,
        //   label: dept.department_name,
        // }));
        setDepartment(res.data.result);
      })
      .catch((err) => console.log(err));

    console.log("department", department);
  }, []);

  const [selectedDepartments, setSelectedDepartments] = useState([]);
  // const [selectedDesignation, setSelectedDesignation] = useState("");
  const handleDepartmentSelection = (e) => {
    const id = parseInt(e.target.value);
    if (selectedDepartments?.includes(id)) {
      setSelectedDepartments(selectedDepartments?.filter(depId => depId !== id));
    } else {
      setSelectedDepartments([...selectedDepartments, id]);
    }
  };

  const [selectedDesignations, setSelectedDesignations] = useState([]);

  const handleDesignationSelection = (e) => {
    const id = parseInt(e.target.value);
    if (selectedDesignations?.includes(id)) {
      setSelectedDesignations(selectedDesignations?.filter(desId => desId !== id));
    } else {
      setSelectedDesignations([...selectedDesignations, id]);
    }
  };

  const [designation, setDesignation] = useState([]);


  useEffect(() => {
    const fetchDesignationsForDepartments = async () => {
      try {
        const response = await fetch(
          `${
            config.API_BASE_URL
          }hr/api/designations?departments=${selectedDepartments.join(",")}`
        );
        const data = await response.json();
        setDesignation(data?.designations);
        setSelectedDesignations(
          selectedDesignations?.filter(desId => 
            data?.designations.some(designation => designation.id === desId)
          )
      );      
      } catch (error) {
        console.error("Error fetching designations:", error);
        return [];
      }
    };
    if(selectedDepartments?.filter(department=>department!==null).length!==0){
    fetchDesignationsForDepartments();
    }
  }, [selectedDepartments]);


  const policySubmitHandler = async (e) => {
    e.preventDefault();

    try {
      const policyHandling = {
        policy_name: validationSchema.fields.policy_name,
        policy_category_id: validationSchema.fields.policy_category_id,
      };

      const dynamicSchema = Yup.object().shape(
        Object.keys(policyDetails).reduce((acc, key) => {
          // Include mandatory fields
          if (policyHandling[key]) {
            acc[key] = policyHandling[key];
          } else if (policyDetails[key]) {
            // Dynamically include other filled fields
            acc[key] = validationSchema.fields[key];
          }
          return acc;
        }, {})
      );

      await dynamicSchema.validate(policyDetails, {
        abortEarly: false,
      });

      const formData = new FormData();
      for (const key in policyDetails) {
        console.log(" policyDetails [ky]", key, "---->", policyDetails[key]);
        formData.append(key, policyDetails[key]);
      }
      formData.append("company_id", company_ID);
      formData.append("created_by", employeeID);
      formData.append("updated_by", employeeID);
      formData.append("departments" , selectedDepartments?.filter(department=>department!==null));
      formData.append("designations" , selectedDesignations?.filter(designation=>designation!==null));
      const res = await postData("hr/upload-policy", formData);
      console.log("response from policy ", res);
      if (res.data.succee == true) {
        //TODO-> add navigation
        console.log("data saved successfully-->", res);
        setInsertedPolicyID(res?.data?.results?.insertId)
        navigate(`../policies`,{
          state: {
            modalOpen:false
          }})
        policyData();
        setErrorMessage("");
        closeModal();
      }
      if (res?.data?.success === false) {
        setErrorMessage(res?.data?.message);
      }
    } catch (error) {
      console.log(error);
      const newError = {};
      error?.inner?.forEach((err) => {
        newError[err.path] = err.message;
      });
      setPolicyDetailsError(newError);
      console.log("yup error : ", newError);
    }
  };

  useEffect(()=>{
    
    setSelectedDepartments(selectedDepartmentId);
    setSelectedDesignations(selectedDesignationtId);

  },[selectedDepartmentId , selectedDesignationtId]);
  console.log("selectedDepartments edited", selectedDepartments);
  console.log("selectedDesignations edited" , selectedDesignations);

  const closeModal = () => {
    setIsModalOpen(false);
  }

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  }

  const filterDepartmentNames = (ids, departments)=> {
    const departmentIdSet = new Set(ids); 
    return departments
        ?.filter(department => departmentIdSet.has(department.id)) 
        .map((department,index) =>index==0?department.department_name:', ' + department.department_name); 
}

const filterDesignation = (ids, designation)=> {
  const designationIdSet = new Set(ids);
  if(designation) {
  return designation
      ?.filter(designation => designationIdSet.has(designation.id)) 
      .map((designation,index) => index==0?designation.designation_name:', ' + designation.designation_name); 
  }
}

  return (
    <>
      {/* Add Policy Modal */}
      <div id="add_policy" className={`modal custom-modal fade ${
          isModalOpen ? "show d-block" : ""
        }`} role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Policy</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeModal}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form method="POST" onSubmit={policySubmitHandler}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Policy Name <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="policy_name"
                    value={policyDetails?.policy_name}
                    onChange={policyHandler}
                  />
                  {policyDetailsError.policy_name && (
                    <span className="text-danger">
                      *{policyDetailsError.policy_name}
                    </span>
                  )}
                </div>
                {!policyDetails?.policy_file && <div className="input-block mb-3">
                  <label className="col-form-label">Description <span className="text-danger">*</span></label>
                  {/* <textarea
                    className="form-control"
                    rows={4}
                    defaultValue={""}
                    value={policyDetails.policy_description}
                    name="policy_description"
                    onChange={policyHandler}
                    
                  /> */}
                  {/* <DefaultEditor  value={text} onChange={handleTextChange} /> */}
                  {/* <ReactQuill value={text} onChange={handleTextChange} style={{ height: '120px' }}/> */}
                  <ReactQuill
                    value={editorContent}
                    onChange={handleEditorChange}
                    style={{ height: "120px" }}
                  />
                  {/* <ReactQuill value={text ? text  : policyDetails?.policy_description} onChange={handleTextChange} /> */}
                </div>}
                <div className="input-block mb-3">
                  <label className={`col-form-label  ${!policyDetails?.policy_file && 'mt-5'}`}>
                    Category <span className="text-danger">*</span>
                  </label>

                  {/* <Select
                    placeholder="All Departments"
                    options={options}
                    onChange={setselectOne}
                    className="select"
                    styles={customStyles}
                  /> */}
                  <select
                    className="form-select"
                    styles={customStyles}
                    placeholder="Select"
                    name="gender"
                    id="policy_dropdown"
                    value={
                      policyCategoryName
                        ? policyCategoryName
                        : policyDetails.policy_category_id
                    }
                    onChange={(event) =>
                      policyCateroryHandler(
                        event,
                        event.target.selectedOptions[0].getAttribute("data-id")
                      )
                    }
                  >
                    {" "}
                    <option value="">Select Category</option>
                    {policyCategory.map((item) => (
                      <option
                        value={item.id}
                        key={item.policy_type}
                        data-id={item.id}
                      >
                        {item.policy_type}
                      </option>
                    ))}
                  </select>
                  {policyDetailsError.policy_category_id && (
                    <span className="text-danger">
                      *{policyDetailsError.policy_category_id}
                    </span>
                  )}
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Department 
                  </label>
                  <div className="dropdown">
                    <button
                      className="btn  dropdown-toggle w-100 text-start"
                      // type="button"
                      id="departmentDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ border: "1px solid gray" }}
                    >
                      Select Department(s)
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="departmentDropdown"
                    >
                      {department?.map((department) => (
                        <li key={department.id} className="text-danger">
                          <label className="dropdown-item">
                            <input
                              type="checkbox"
                              value={department.id}
                              onChange={(e) => handleDepartmentSelection(e)}
                              // checked={selectedDepartments?.includes(department.id)}
                            />
                            {department.department_name}
                          </label>
                        </li>
                      ))}
                    </ul>
                    <span>{filterDepartmentNames(selectedDepartments,department)}</span>
                  </div>
                  {policyDetailsError.departments && (
                    <span className="text-danger">
                      *{policyDetailsError.departments}
                    </span>
                  )}
                </div>

                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Designation 
                  </label>
                  <div className="dropdown">
                    <button
                     className="btn  dropdown-toggle w-100 text-start"
                    //  type="button"
                     id="departmentDropdown"
                     data-bs-toggle="dropdown"
                     aria-expanded="false"
                     style={{ border: "1px solid gray" }}
                    >
                      Select Designation
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="designationDropdown"
                    >
                      {designation?.map((designation) => (
                        <li key={designation.id}>
                          <label className="dropdown-item">
                            <input
                              type="checkbox"
                              name="designation"
                              value={designation.id}
                              onChange={(e) => handleDesignationSelection(e)}
                              // checked={selectedDesignations?.includes(designation.id)}
                            />
                            {' '}{filterDepartmentNames([designation.department_id], department)} - {designation.designation_name}
                          </label>
                        </li>
                      ))}
                    </ul>
                    <span>{filterDesignation(selectedDesignations,designation)}</span>
                  </div>
                  {policyDetailsError.designation && (
                    <span className="text-danger">
                      *{policyDetailsError.designation}
                    </span>
                  )}
                </div>

                {!policyDetails?.generate_policy && <div className="input-block mb-3">
                  <label className="col-form-label">Upload Policy <span className="text-danger">*</span></label>
                  <input
                    type="file"
                    className="form-control"
                    id="policy_upload"
                    name="policy_file"
                    onChange={policyHandlerFile}
                    accept=".pdf"
                  />
                  <span
                    className="text-secondary "
                    style={{ fontSize: "12px" }}
                  >
                    {" "}
                    *File Must be of 50 KB
                  </span>
                  {policyDetailsError.policy_file && (
                    <p className="text-danger">
                      *{policyDetailsError.policy_file}
                    </p>
                  )}
                </div>}
                {/* {errorMessage && (<div style={{color:"red"}}>* {errorMessage}</div>)} */}
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                    disabled={policyDetails?.policy_name && (policyDetails?.policy_file || policyDetails?.generate_policy) && policyDetails?.policy_category_id ? false : true}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Policy Modal */}
      {/* Edit Policy Modal */}
      <div id="edit_policy" className={`modal custom-modal fade ${
          isEditModalOpen ? "show d-block" : ""
        }`} role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Policy</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeEditModal}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={policyEditSubmitHandler}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Policy Name <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    defaultValue="Leave Policy"
                    value={policyDetails.policy_name}
                    name="policy_name"
                    onChange={policyHandler}
                  />
                  {policyDetailsError.policy_name && (
                    <span className="text-danger">
                      *{policyDetailsError.policy_name}
                    </span>
                  )}
                </div>
                {!policyDetails?.policy_file && <div className="input-block mb-3 ">
                  <label className="col-form-label">
                    Description <span className="text-danger">*</span>
                  </label>
                  {/* <textarea
                    className="form-control"
                    rows={4}
                    defaultValue={""}
                    value={policyDetails.policy_description}
                    name="policy_description"
                    onChange={policyHandler}
                  /> */}
                  <ReactQuill
                    value={text ? text : policyDetails?.generate_policy}
                    onChange={handleTextChange}
                    style={{ height: "120px" }}
                  />
                  {/* <ReactQuill value={editorContent} onChange={handleEditorChange} /> */}
                </div>}
                {/* <div className="input-block mb-3">
                  <label className="col-form-label">Department</label>
                  <Select
                    placeholder="All Departments"
                    options={optionsTwo}
                    onChange={setselectTwo}
                    className="select"
                    styles={customStyles}
                  />
                </div> */}

                  <div className={`input-block mb-3  ${!policyDetails?.policy_file && 'mt-5'}`}>
                  <label className="col-form-label">
                    Category <span className="text-danger">*</span>
                  </label>

                  {/* <Select
                    placeholder="All Departments"
                    options={options}
                    onChange={setselectOne}
                    className="select"
                    styles={customStyles}
                  /> */}
                  <select
                    className="form-select"
                    styles={customStyles}
                    placeholder="Select"
                    name="gender"
                    id="edit_policy_dropdown"
                    value={
                      policyCategoryName
                        ? policyCategoryName
                        : policyDetails.policy_category_id
                    }
                    onChange={(event) =>
                      policyCateroryHandler(
                        event,
                        event.target.selectedOptions[0].getAttribute("data-id")
                      )
                    }
                  >
                    {" "}
                    <option value="">Select Category</option>
                    {policyCategory.map((item) => (
                      <option
                        value={item.id}
                        key={item.policy_type}
                        data-id={item.id}
                      >
                        {item.policy_type}
                      </option>
                    ))}
                  </select>
                  {policyDetailsError.policy_category_id && (
                    <span className="text-danger">
                      *{policyDetailsError.policy_category_id}
                    </span>
                  )}
                </div>

                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Department 
                  </label>
                  <div className="dropdown">
                    <button
                      className="btn  dropdown-toggle w-100 text-start"
                      // type="button"
                      id="departmentDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ border: "1px solid gray" }}
                    >
                      Select Department(s)
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="departmentDropdown"
                    >
                      {department?.map((department) => (
                        <li key={department.id} className="text-danger">
                          <label className="dropdown-item">
                            <input
                              type="checkbox"
                              value={department.id}
                              onChange={(e) => handleDepartmentSelection(e)}
                              checked={selectedDepartments?.includes(department.id)} 
                            />
                            {department.department_name}
                          </label>
                        </li>
                      ))}
                    </ul>
                    <span>{filterDepartmentNames(selectedDepartments,department)}</span>
                  </div>
                  {policyDetailsError.departments && (
                    <span className="text-danger">
                      *{policyDetailsError.departments}
                    </span>
                  )}
                </div>

                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Designation 
                  </label>
                  <div className="dropdown">
                    <button
                     className="btn  dropdown-toggle w-100 text-start"
                    //  type="button"
                     id="departmentDropdown"
                     data-bs-toggle="dropdown"
                     aria-expanded="false"
                     style={{ border: "1px solid gray" }}
                    >
                      Select Designation
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="designationDropdown"
                    >
                      {designation.map((designation) => (
                        <li key={designation.id}>
                          <label className="dropdown-item">
                            <input
                              type="checkbox"
                              name="designation"
                              value={designation.id}
                              onChange={(e) => handleDesignationSelection(e)}
                              checked={selectedDesignations?.includes(designation.id)}
                            />
                            {' '}{filterDepartmentNames([designation.department_id], department)} - {designation.designation_name}
                          </label>
                        </li>
                      ))}
                    </ul>
                    <span>{filterDesignation(selectedDesignations,designation)}</span>
                  </div>
                  {policyDetailsError.designation && (
                    <span className="text-danger">
                      *{policyDetailsError.designation}
                    </span>
                  )}
                </div>

                {!policyDetails?.generate_policy &&<div className="input-block mb-3">
                  <label className="col-form-label">Upload Policy <span className="text-danger">*</span></label>
                  <input
                    type="file"
                    className="form-control"
                    id="edit_policy_upload"
                    name="policy_file"
                    onChange={policyHandlerFile}
                  />
                </div>}
                {policyDetailsError.policy_file && (
                  <p className="text-danger">
                    *{policyDetailsError.policy_file}
                  </p>
                )}
                {policyDetails.policy_file ? (
                  <span>
                    {policyDetails?.policy_file?.name
                      ? policyDetails.policy_file?.name
                      : policyDetails.policy_file}
                  </span>
                ) : (
                  ""
                )}
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    disabled={policyDetails?.policy_name && (policyDetails?.policy_file || policyDetails?.generate_policy) && policyDetails?.policy_category_id ? false : true}
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Policy Modal */}
    </>
  );
};

export default AddandEditPolicy;
