import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table } from "antd";
import moment from "moment";
import Breadcrumbs from "../../../components/Breadcrumbs";
import AttendanceEmployeeFilter from "../../../components/AttendanceEmployeeFilter";
import config from "./../../../config";
import { calculateOvertime, convertTimeFormat, formatDateToFullDateString } from "../../../validation";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AttendanceApprovalForm from "./AttendanceApprovalForm";

const AttendanceEmployee = () => {
  const estimatedWorkingHours = 8;
  const [data, setData] = useState([]);
  const [activity, setActivity] = useState([]);
  const [totalHoursToday, setTotalHoursToday] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [attendanceApprovalData, setAttendanceApprovalData]= useState({});
  // Replace with the actual employee ID from your authentication logic

  function getCurrentDate() {
    const currentDate = new Date();
    return currentDate.toISOString().slice(0, 10);
  } 

  let employeeId = localStorage.getItem("employeeID");
  useEffect(() => {
    axios
      .get(`${config.API_BASE_URL}api/attendance/${employeeId}`)
      .then((res) => {
        console.log("Attendance data fetched:", res.data);
        setData(res.data);
      })
      .catch((err) => console.error("Error fetching attendance data:", err));
  }, []);

  console.log("Attendance data fetched:", data);

  useEffect(() => {
    axios
      .get(`${config.API_BASE_URL}api/attendance/today/${employeeId}`)
      .then((res) => {
        console.log("Today's activity data fetched:", res.data);
        setActivity(res?.data?.results);

        let totalHours = 0;
        const punchIns = res?.data?.results.filter(
          (act) => act.title === "Login"
        );
        const punchOuts = res?.data?.results.filter(
          (act) => act.title === "Logout"
        );
        console.log("punch ins->", punchIns);

        if (punchIns?.length > 0 && punchOuts?.length > 0) {
          const firstPunchIn = moment(punchIns[0].time);
          const lastPunchOut = moment(punchOuts[punchOuts.length - 1].time);
          totalHours = lastPunchOut.diff(firstPunchIn, "hours", true);
          console.log("total hours-->", totalHours);
          setTotalHoursToday(totalHours);
        }else{
          const firstPunchIn = moment(punchIns[0].time);
          const lastPunchOut = moment(new Date().time);
          totalHours = lastPunchOut.diff(firstPunchIn, "hours", true);
          setTotalHoursToday(totalHours);
          console.log("total hours-->", totalHours);
        }

       
      })
      .catch((err) => console.error("Error fetching today's activity:", err));
  }, [employeeId]);



  const formatTotalHours = (totalHours) => {
    const hours = Math.floor(totalHours);
    const minutes = Math.floor((totalHours % 1) * 60);
    const seconds = Math.round(((totalHours * 60) % 1) * 60);

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds} hrs`;
  };

  const fetchTotalHours = (punchIns, punchOuts) => {
    let totalHours = 0;

    if (punchIns?.length > 0 && punchOuts?.length > 0) {
        // Ensure punchOuts has enough data to match with punchIns
        const validPunchOuts = punchOuts.slice(0, punchIns.length);

        // Calculate total hours based on punch-ins and corresponding punch-outs
        let totalMinutes = 0;
        for (let i = 0; i < validPunchOuts.length; i++) {
            const punchInTime = moment(punchIns[i].time);
            const punchOutTime = moment(validPunchOuts[i].time);
            totalMinutes += punchOutTime.diff(punchInTime, 'minutes');
        }

        totalHours = totalMinutes / 60;
    } else {
        const firstPunchIn = punchIns?.length > 0 ? moment(punchIns[0]?.time) : null;
        const punchInDate = firstPunchIn ? firstPunchIn.format('YYYY-MM-DD') : null;
        const currentDate = moment().format('YYYY-MM-DD');

        if (!punchOuts || punchOuts?.length === 0) {
            if (punchInDate !== currentDate) {
                // If punch-in date is not today
                totalHours = 0; // Set total hours to 0
            } else {
                // If punch-in date is today
                if (firstPunchIn) {
                    const lastPunchOut = moment(); // Current time
                    totalHours = lastPunchOut.diff(firstPunchIn, "hours", true);
                }
            }
        }
    }

    // Format total hours as HH:mm:ss
    const hours = Math.floor(totalHours);
    const minutes = Math.floor((totalHours - hours) * 60);
    const seconds = Math.floor(((totalHours - hours) * 60 - minutes) * 60);

    const formatTime = (num) => num.toString().padStart(2, "0");
    const formattedTotalHours = `${formatTime(hours)}:${formatTime(minutes)}:${formatTime(seconds)}`;

    console.log("Total hours -->", formattedTotalHours);
    return formattedTotalHours;
};


  const userElements = data.map((user, index) => ({
    key: index,
    id: index + 1,
    Date: user.Date,
    PunchIn: user?.punchIn ?  moment(user.punchIn).format("hh:mm A"):'..',
    PunchOut: user?.punchOut ? moment(user.punchOut).format("hh:mm A"): 'N/A',
    TotalHours:
    // fetchTotalHours(user.punchIn , user.punchOut )
      user.totalHours !== undefined && !isNaN(user.totalHours)
        ? formatTotalHours(user.totalHours) !== `00:00:00 hrs` ? formatTotalHours(user.totalHours) : fetchTotalHours(user.punchIn , user.punchOut )
        : "N/A",
      attendance_id: user.id,
      marked_full_day_attendance: user.marked_full_day_attendance,
      employeeId: user.employee_id,
      total_hours: user.totalHours,
      attendance_approval_status:user.attendance_approval_status
  }));

  console.log(userElements);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Date",
      dataIndex: "Date",
      sorter: (a, b) => new Date(a.Date) - new Date(b.Date),
    },
    {
      title: "PunchIn",
      dataIndex: "PunchIn",
      sorter: (a, b) => new Date(a.PunchIn) - new Date(b.PunchIn),
    },
    {
      title: "PunchOut",
      dataIndex: "PunchOut",
      sorter: (a, b) => new Date(a.PunchOut) - new Date(b.PunchOut),
    },
    {
      title: "Total Hours",
      dataIndex: "TotalHours",
      sorter: (a, b) => parseFloat(a.TotalHours) - parseFloat(b.TotalHours),
    },
    {
      title: "Approval Status",
      dataIndex: "attendance_approval_status",
      render:(text,record)=>{
        return <span style={{color: text=='pending'?'rgb(255, 223, 0)': text=='rejected' ? 'red' :'green' }}>{text ? text.charAt(0).toUpperCase() + text.slice(1) : '-'}</span>
      },
    },
    {
      title: "Action",
      dataIndex: "",
      render:(_,record)=>{
        const currentDate = getCurrentDate();
        const isDisabled = currentDate === record.Date || 
        record.attendance_approval_status != null; 
        return (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip-view" style={{ fontSize: "12px", padding: "2px" }}>
                {!isDisabled ? 'Apply for Approval': 'Not allowed'}
              </Tooltip>
            }
          >
            <i
              className="fa fa-paper-plane"
              style={{
                color: isDisabled ? '#B0B0B0' : '#1E90FF', 
                cursor: isDisabled ? 'not-allowed' : 'pointer',
              }}
              onClick={() => {
                if (!isDisabled) { 
                  setIsModalOpen(true);
                  setAttendanceApprovalData(record);
                }
              }}
            ></i>
          </OverlayTrigger>
          );
      }
    }
  ];

  // Display total hours in circles
  const totalHoursCircles = totalHoursToday.toFixed(2); // Ensure it matches the table display

  // ---------- todays data ------------------------

  const filterTodayData = (data) => {
    console.log("data", data);
    const today = new Date().toISOString().split("T")[0];
    console.log("today date", today); // Get today's date in YYYY-MM-DD format
    return data.filter((item) => item.Date.startsWith(today));
  };
  const todaysData = filterTodayData(data);
  console.log("todays data ", todaysData);

  //----------------------- over time or  total hours ----------------------

  //   const parseDateTime = (dateTimeString) => new Date(dateTimeString.replace(' ', 'T'));
  //   let totalWorkTime = 0;
  //   let loginTime = null;
  //   for (let activity of activityDetail) {
  //     if (activity.activity_type === 'Login') {
  //         loginTime = parseDateTime(activity.activity_time);
  //     } else if (activity.activity_type === 'Logout' && loginTime) {
  //         const logoutTime = parseDateTime(activity.activity_time);
  //         totalWorkTime += logoutTime - loginTime;
  //         loginTime = null;
  //     }
  // }

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Attendance"
            title="Dashboard"
            subtitle="Attendance"
          />
          <div className="row">
            <div className="col-md-4">
              <div className="card punch-status">
                <div className="card-body">
                  <h5 className="card-title">
                    Timesheet{" "}
                    <small className="text-muted">
                      {moment().format("DD MMM YYYY")}
                    </small>
                  </h5>
                  <div className="punch-det">
                    <h6>Punch In at</h6>
                    <p>
                    { todaysData[0]?.date &&  formatDateToFullDateString(todaysData[0]?.date)}{" "} {" "}
                      {/* {todaysData[0]?.PunchIn &&
                        convertTimeFormat(todaysData[0]?.punchIn)} */}
                        {moment(todaysData[0]?.punchIn).format("hh:mm A")}
                    </p>
                  </div>
                  <div className="punch-info">
                    <div
                      className="punch-hours "
                      style={{ textAlign: "center" }}
                    >
                      <span>
                        {totalHoursToday && formatTotalHours(totalHoursToday)}
                      </span>
                    </div>
                  </div>
                  <div className="punch-det">
                    <h6>Punch Out at</h6>
                    <p>
                    { todaysData[0]?.date &&  formatDateToFullDateString(todaysData[0]?.date)}{" "} {" "}
                        {todaysData[0]?.punchOut && moment(todaysData[0]?.punchOut).format("hh:mm A") }
                    </p>
                  </div>
                  {/* <div className="statistics">
                    <div className="row">
                      <div className="col-md-6 col-6 text-center">
                        <div className="stats-box">
                          <p>Break</p>
                          <h6>1.00 hrs</h6>
                        </div>
                      </div>
                      <div className="col-md-6 col-6 text-center">
                        <div className="stats-box">
                          <p>Overtime</p>
                          <h6>
                            {calculateOvertime(
                              formatTotalHours(totalHoursToday),
                              estimatedWorkingHours
                            )}
                          </h6>{" "}
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card recent-activity">
                <div className="card-body">
                  <h5 className="card-title">Today's Activity</h5>
                  <ul className="res-activity-list">
                    {activity.length > 0 ? (
                      activity.map((act, index) => (
                        <li key={index}>
                          <p className="mb-0">
                            {act.title == "Login"
                              ? "Punch In at"
                              : "Punch Out at"}
                          </p>
                          <p className="res-activity-time">
                            <i className="fa fa-clock-o"></i>{" "}
                            {moment(act.time).format("hh:mm A")}
                          </p>
                        </li>
                      ))
                    ) : (
                      <li>No activities for today</li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row filter-row">
            <AttendanceEmployeeFilter />
          </div> */}
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Attendance</h5>
                  <Table
                    className="table-striped"
                    pagination={{
                      total: data.length,
                      showTotal: (total, range) =>
                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                      showSizeChanger: true,
                      itemRender: (current, type, originalElement) => {
                        if (type === "prev") {
                          return <a>Previous</a>;
                        }
                        if (type === "next") {
                          return <a>Next</a>;
                        }
                        return originalElement;
                      },
                    }}
                    style={{ overflowX: "auto" }}
                    columns={columns}
                    dataSource={userElements}
                    rowKey={(record) => record.id}
                     scroll={{ x: true }} 
                  tableLayout="auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AttendanceApprovalForm attendanceApprovalData={attendanceApprovalData} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>
      {isModalOpen && <div className="modal-backdrop fade show"></div>}
    </>
  );
};

export default AttendanceEmployee;
