import { roundoffAmount } from "./roundoffAmount";

export const calculateSalaryBreakdown = (salary, config) => {
  const basic = salary * (config.basic_percentage / 100);
  const hra = basic * (config.hra_percentage / 100);
  const medicalAllowance =
    basic * (config.medical_allowance_percentage / 100);
  const statutoryBonus = basic * (config.statutory_bonus_percentage / 100);
  const otherAllowance = basic * (config.other_allowance_percentage / 100);
  const totalPay =
    (basic) + (hra) + (medicalAllowance) + (statutoryBonus) + (otherAllowance);

  return {
    basic: basic.toFixed(1),
    hra: hra.toFixed(1),
    medicalAllowance: medicalAllowance.toFixed(1),
    statutoryBonus: statutoryBonus.toFixed(1),
    otherAllowance: otherAllowance.toFixed(1),
    totalPay: roundoffAmount(totalPay),
  };
};