import * as XLSX from "xlsx";

export const exportToExcel = (data, fileName) => {
  return new Promise((resolve, reject) => {
    try {
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, `${fileName}.xlsx`);
      resolve("Excel file exported successfully!");
    } catch (error) {
      reject("Failed to export Excel file.");
    }
  });
};
